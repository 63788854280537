import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrl: './date-filter.component.scss',
})
export class DateFilterComponent implements OnInit {
  @Input() startingValue: string[] | undefined = undefined;
  @Input() placeholder!: string;
  private _clear = false;
  @Input() set clear(value: boolean) {
    if (value === true) {
      this.onClear();
    }
    this._clear = value;
  }

  @Output() changeSorting = new EventEmitter<string[]>();

  dates: Date[] | undefined;

  ngOnInit(): void {
    if (!this.startingValue) return;
    this.dates = this.startingValue.map((date) => this.parseDateString(date));
  }

  onClear() {
    this.dates = undefined;
  }
  onChange(): void {
    const formattedDate: string[] = [];
    this.dates?.forEach((date) => {
      const formattedDateElement = this.formatDate(date);
      formattedDate.push(formattedDateElement);
    });
    this.changeSorting.emit(formattedDate);
  }

  private formatDate(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  }

  parseDateString(dateStr: string): Date {
    const parts = dateStr.split('.');
    if (parts.length === 3) {
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[2], 10);
      return new Date(year, month, day);
    } else {
      throw new Error('Invalid date format');
    }
  }
}
