import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-dialog-list',
  templateUrl: './dialog-list.component.html',
  styleUrl: './dialog-list.component.scss',
})
export class DialogListComponent {
  @Input() list!: any[];
  @Input() title!: string;
  @Input() show = false;
  @Output() onHide = new EventEmitter<void>();

  notifyHide() {
    this.onHide.emit();
  }
}
