<p-sidebar
  [(visible)]="isFilterSidebarOpen"
  position="right"
  (onHide)="onCloseSidebar()"
>
  <h3 class="mb-4 text-xl">Filtry:</h3>
  <div class="filters-wrapper mb-5">
    <ng-container *ngFor="let filter of filtersConfig">
      <ng-container [ngSwitch]="filter.type">
        <div
          class="input-filter-container filter-container"
          *ngSwitchCase="'input'"
        >
          <app-input-filter
            [clear]="clearChildsFilters"
            (changeSorting)="onChange($event, 'query', 'contains')"
            [value]="filter.startingValue"
          ></app-input-filter>
        </div>
        <div class="filter-container" *ngSwitchCase="'dropdown'">
          <h4 *ngIf="filter.label" class="mb-3">{{ filter.label }}</h4>
          <app-dropdown-filter
            [options]="filter.predefinedData"
            (changeSorting)="
              onChange($event, filter.fieldName, filter.matchMode)
            "
            [optionLabel]="filter.optionLabel"
            [placeholder]="filter.placeholder"
            [clear]="clearChildsFilters"
            [selectedOption]="filter.startingValue"
          >
          </app-dropdown-filter>
        </div>
        <div class="filter-container" *ngSwitchCase="'dropdown-string'">
          <h4 *ngIf="filter.label" class="mb-3">{{ filter.label }}</h4>
          <app-dropdown-string-value-filter
            [options]="filter.predefinedData"
            (changeSorting)="
              onChange($event, filter.fieldName, filter.matchMode)
            "
            [placeholder]="filter.placeholder"
            [clear]="clearChildsFilters"
            [selectedOption]="filter.startingValue"
          >
          </app-dropdown-string-value-filter>
        </div>
        <div class="filter-container" *ngSwitchCase="'multi-dropdown'">
          <h4 *ngIf="filter.label" class="mb-3">{{ filter.label }}</h4>
          <app-dropdown-multi-filter
            [options]="filter.predefinedData"
            (changeSorting)="
              onChange($event, filter.fieldName, filter.matchMode)
            "
            [placeholder]="filter.placeholder"
            [clear]="clearChildsFilters"
            [selectedOptions]="filter.startingValue"
          >
          </app-dropdown-multi-filter>
        </div>
        <div class="filter-container" *ngSwitchCase="'date'">
          <h4 *ngIf="filter.label" class="mb-3">{{ filter.label }}</h4>
          <app-date-filter
            (changeSorting)="
              onChange($event, filter.fieldName, filter.matchMode)
            "
            [placeholder]="filter.placeholder"
            [clear]="clearChildsFilters"
            [startingValue]="filter.startingValue"
          >
          </app-date-filter>
        </div>
        <div class="filter-container" *ngSwitchCase="'autocomplete'">
          <h4 *ngIf="filter.label" class="mb-3">{{ filter.label }}</h4>
          <app-autocomplete-filter
            [url]="filter.url"
            [placeholder]="filter.placeholder"
            [clear]="clearChildsFilters"
            (changeSorting)="
              onChange($event, filter.fieldName, filter.matchMode)
            "
            [startValue]="filter.startingValue"
          >
          </app-autocomplete-filter>
        </div>
        <div
          class="filter-container filter-container--tri"
          *ngSwitchCase="'triStateCheck'"
        >
          <h4 *ngIf="filter.label" class="tri-state-label">
            {{ filter.label }}
          </h4>
          <app-tri-state-filter
            [clear]="clearChildsFilters"
            [name]="filter.label"
            (changeSorting)="
              onChange($event, filter.fieldName, filter.matchMode)
            "
            [selectedOption]="filter.startingValue"
          ></app-tri-state-filter>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <p-button
    type="button"
    icon="pi pi-filter-slash"
    label="Wyczyść filtry"
    severity="secondary"
    (click)="handleClearFilters()"
  ></p-button>
</p-sidebar>
