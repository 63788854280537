import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { DropdownChangeEvent } from 'primeng/dropdown';

@Component({
  selector: 'app-dropdown-filter',
  templateUrl: './dropdown-filter.component.html',
  styleUrl: './dropdown-filter.component.scss',
})
export class DropdownFilterComponent implements OnInit {
  @Input() optionLabel!: string;
  @Input() selectedOption: any | null = null;
  @Input({ required: true }) options!: any[];
  @Input({ required: true }) placeholder!: string;
  @Input() set clear(value: boolean) {
    if (value === true) {
      this.onClear();
    }
  }
  @Output() changeSorting = new EventEmitter<string>();
  ngOnInit(): void {
    if (this.optionLabel) return;

    this.optionLabel = 'name';
  }
  onChange(event: DropdownChangeEvent) {
    this.changeSorting.emit(event.value);
  }

  onClear() {
    this.selectedOption = null;
  }
}
