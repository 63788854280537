import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-switch-input',
  templateUrl: './switch-input.component.html',
  styleUrl: './switch-input.component.scss',
})
export class SwitchInputComponent implements OnInit {
  ngOnInit(): void {
    console.log(this.inputId);
  }
  @Input({ required: true }) controlName!: FormControl<any>;
  @Input({ required: true }) label!: string;
  @Input({ required: true }) inputId!: string;
}
