import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-label-table-field',
  templateUrl: './label-table-field.component.html',
  styleUrl: './label-table-field.component.scss',
})
export class LabelTableFieldComponent {
  @Input({ required: true }) value!: string;
  @Input({ required: true }) severity!:
    | 'success'
    | 'info'
    | 'warning'
    | 'danger';
}
