<span class="p-input-icon-left ml-auto">
  <i class="pi pi-search"></i>
  <input
    [(ngModel)]="value"
    pInputText
    type="text"
    placeholder="Search keyword"
    (input)="onInput($event)"
  />
</span>
