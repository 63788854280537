import { Component, Input, OnInit } from '@angular/core';
import { IDocumentDetailsPhoto } from '../../../warehouse/models/documentDetailsPhoto';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrl: './gallery.component.scss',
})
export class GalleryComponent implements OnInit {
  ngOnInit(): void {
    console.log(this.dataPhotos);
  }
  @Input({ required: true }) dataPhotos!: IDocumentDetailsPhoto[];
}
