<p-timeline *ngIf="events" [value]="events">
  <ng-template pTemplate="marker" let-event>
    <span
      class="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1"
      [style]="{ 'background-color': 'white' }"
    >
      <i
        [class]="'pi ' + event.direction.icon"
        [ngStyle]="{
          color: event.direction.class === 'success' ? 'blue' : 'red'
        }"
      ></i>
    </span>
  </ng-template>
  <ng-template pTemplate="content" let-event>
    <div class="event-wrapper">
      <h2>{{ event.item_name }}</h2>
      <p>{{ event.batch_number_batch_number }}</p>
      <p>{{ event.user_name }}</p>
      <p>{{ event.date }}</p>
      <p>{{ event.direction_name }}</p>
    </div>
  </ng-template>
</p-timeline>
<app-filter-element
  (clearFilters)="clear()"
  (changeSorting)="handleFilterChange($event)"
  [filtersConfig]="filtersConfig"
  [isFilterSidebarOpen]="isFilterSidebarOpen"
  (closeFilterSidebar)="toggleFilterSidebar()"
></app-filter-element>
<p-button
  type="button"
  class="mr-2"
  icon="pi pi-filter"
  label="Filtry ({{ activeFilters }})"
  severity="primary"
  (click)="toggleFilterSidebar()"
  [outlined]="true"
></p-button>
<p-button
  type="button"
  class="mr-2"
  icon="pi pi-filter"
  label="Załaduj więcej"
  severity="primary"
  (click)="onLoadMore()"
  [outlined]="true"
></p-button>
