import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-url-table-field',
  templateUrl: './url-table-field.component.html',
  styleUrl: './url-table-field.component.scss',
})
export class UrlTableFieldComponent {
  @Input({ required: true }) value!: any;
}
