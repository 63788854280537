export enum CLIENTS_API_ENDPOINTS {
  base = 'clients/',
  Export = base + 'export',
  List = base + 'list',
  Details = base + 'get/',
  GetEmployees = 'client-contacts/list',
  GetEmployee = 'client-contacts/get/',
  AddEmployee = 'client-contacts/store',
  EditEmployee = 'client-contacts/edit/',
  DeleteEmployee = 'client-contacts/delete/',
}
