import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-boolean-table-field',
  templateUrl: './boolean-table-field.component.html',
  styleUrl: './boolean-table-field.component.scss',
})
export class BooleanTableFieldComponent {
  @Input({ required: true }) value!: boolean;
}
