<div class="subpage-wrapper">
  <p-table
    [value]="data"
    dataKey="id"
    styleClass="p-datatable-gridlines"
    class="custom-orders-table"
  >
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 4rem"></th>
        <ng-container *ngFor="let firstLevelCol of columnsConfig.firstLevel">
          <th
            pResizableColumn
            [pSortableColumn]="
              firstLevelCol.sortable ? firstLevelCol.field : undefined
            "
          >
            {{ firstLevelCol.header }}
            <p-sortIcon
              *ngIf="firstLevelCol.sortable"
              [field]="firstLevelCol.field"
            ></p-sortIcon>
          </th>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-dataElement>
      <div class="table-level-info-wrapper">
        <p class="table-level-info">
          {{ columnsConfig.levelNames.first }}
        </p>
      </div>

      <tr>
        <td>
          <button
            pButton
            type="button"
            [icon]="
              expandedRows[dataElement.temporaryId]
                ? 'pi pi-chevron-up'
                : 'pi pi-chevron-down'
            "
            (click)="toggleRow(dataElement)"
          ></button>
        </td>
        <ng-container *ngFor="let col of columnsConfig.firstLevel">
          <ng-container [ngSwitch]="col.type">
            <td *ngSwitchCase="'text'">
              <app-text-table-field
                [value]="dataElement[col.field]"
              ></app-text-table-field>
            </td>

            <td *ngSwitchCase="'decimal'">
              <app-decimal-table-field
                [value]="dataElement[col.field]"
              ></app-decimal-table-field>
            </td>

            <td *ngSwitchCase="'decimal-color'">
              <app-decimal-color-table-field
                [value]="dataElement[col.field]"
              ></app-decimal-color-table-field>
            </td>
            <td *ngSwitchCase="'icon'">
              <app-icon-table-field
                [value]="dataElement[col.field]"
              ></app-icon-table-field>
            </td>
            <td *ngSwitchCase="'icon-status'">
              <app-icon-status-field
                [value]="dataElement[col.field]"
              ></app-icon-status-field>
            </td>
            <td *ngSwitchCase="'progress'">
              <app-progress [value]="dataElement[col.field]"></app-progress>
            </td>

            <td *ngSwitchCase="'label'">
              <app-label-table-field
                severity="info"
                [value]="dataElement[col.field]"
              ></app-label-table-field>
            </td>

            <td *ngSwitchCase="'boolean'">
              <app-boolean-table-field
                [value]="dataElement[col.field]"
              ></app-boolean-table-field>
            </td>

            <td *ngSwitchCase="'url'">
              <app-url-table-field
                [value]="dataElement[col.field]"
              ></app-url-table-field>
            </td>
          </ng-container>
        </ng-container>
      </tr>
      <tr *ngIf="isPalletsVisible(dataElement)">
        <td [attr.colspan]="calculatedRowSpan">
          <p-table [value]="dataElement[dataKeys.secoundLevel]">
            <ng-template pTemplate="header">
              <tr>
                <th style="width: 4rem"></th>
                <ng-container
                  *ngFor="let secoundLevelCol of columnsConfig.secoundLevel"
                >
                  <th
                    pResizableColumn
                    [pSortableColumn]="
                      secoundLevelCol.sortable
                        ? secoundLevelCol.field
                        : undefined
                    "
                  >
                    {{ secoundLevelCol.header }}
                    <p-sortIcon
                      *ngIf="secoundLevelCol.sortable"
                      [field]="secoundLevelCol.field"
                    ></p-sortIcon>
                  </th>
                </ng-container>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-secoundLevelData>
              <div class="table-level-info-wrapper">
                <p class="table-level-info">
                  {{ columnsConfig.levelNames.secound }}
                </p>
              </div>
              <tr>
                <td>
                  <button
                    pButton
                    type="button"
                    [icon]="
                      expandedSecoundLevelRows[secoundLevelData.id]
                        ? 'pi pi-chevron-up'
                        : 'pi pi-chevron-down'
                    "
                    (click)="togglePalletRow(secoundLevelData)"
                  ></button>
                </td>
                <ng-container *ngFor="let col of columnsConfig.secoundLevel">
                  <ng-container [ngSwitch]="col.type">
                    <td *ngSwitchCase="'text'">
                      <app-text-table-field
                        [value]="secoundLevelData[col.field]"
                      ></app-text-table-field>
                    </td>

                    <td *ngSwitchCase="'decimal'">
                      <app-decimal-table-field
                        [value]="secoundLevelData[col.field]"
                      ></app-decimal-table-field>
                    </td>

                    <td *ngSwitchCase="'decimal-color'">
                      <app-decimal-color-table-field
                        [value]="secoundLevelData[col.field]"
                      ></app-decimal-color-table-field>
                    </td>
                    <td *ngSwitchCase="'icon'">
                      <app-icon-table-field
                        [value]="secoundLevelData[col.field]"
                      ></app-icon-table-field>
                    </td>
                    <td *ngSwitchCase="'icon-status'">
                      <app-icon-status-field
                        [value]="secoundLevelData[col.field]"
                      ></app-icon-status-field>
                    </td>
                    <td *ngSwitchCase="'progress'">
                      <app-progress
                        [value]="secoundLevelData[col.field]"
                      ></app-progress>
                    </td>

                    <td *ngSwitchCase="'label'">
                      <app-label-table-field
                        severity="info"
                        [value]="secoundLevelData[col.field]"
                      ></app-label-table-field>
                    </td>

                    <td *ngSwitchCase="'boolean'">
                      <app-boolean-table-field
                        [value]="secoundLevelData[col.field]"
                      ></app-boolean-table-field>
                    </td>

                    <td *ngSwitchCase="'url'">
                      <app-url-table-field
                        [value]="secoundLevelData[col.field]"
                      ></app-url-table-field>
                    </td>
                  </ng-container>
                </ng-container>
              </tr>
              <tr *ngIf="isItemsVisible(secoundLevelData)">
                <td [attr.colspan]="calculatedRowSpan">
                  <p-table [value]="secoundLevelData[dataKeys.thirdLevel]">
                    <ng-template pTemplate="header">
                      <tr>
                        <ng-container
                          *ngFor="let thirdLevelCol of columnsConfig.thirdLevel"
                        >
                          <th
                            pResizableColumn
                            [pSortableColumn]="
                              thirdLevelCol.sortable
                                ? thirdLevelCol.field
                                : undefined
                            "
                          >
                            {{ thirdLevelCol.header }}
                            <p-sortIcon
                              *ngIf="thirdLevelCol.sortable"
                              [field]="thirdLevelCol.field"
                            ></p-sortIcon>
                          </th>
                        </ng-container>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-thirdLevelData>
                      <div class="table-level-info-wrapper">
                        <p class="table-level-info">
                          {{ columnsConfig.levelNames.third }}
                        </p>
                      </div>
                      <tr>
                        <ng-container
                          *ngFor="let col of columnsConfig.thirdLevel"
                        >
                          <ng-container [ngSwitch]="col.type">
                            <td *ngSwitchCase="'text'">
                              <app-text-table-field
                                [value]="thirdLevelData[col.field]"
                              ></app-text-table-field>
                            </td>

                            <td *ngSwitchCase="'decimal'">
                              <app-decimal-table-field
                                [value]="thirdLevelData[col.field]"
                              ></app-decimal-table-field>
                            </td>

                            <td *ngSwitchCase="'decimal-color'">
                              <app-decimal-color-table-field
                                [value]="thirdLevelData[col.field]"
                              ></app-decimal-color-table-field>
                            </td>
                            <td *ngSwitchCase="'icon'">
                              <app-icon-table-field
                                [value]="thirdLevelData[col.field]"
                              ></app-icon-table-field>
                            </td>
                            <td *ngSwitchCase="'icon-status'">
                              <app-icon-status-field
                                [value]="thirdLevelData[col.field]"
                              ></app-icon-status-field>
                            </td>
                            <td *ngSwitchCase="'progress'">
                              <app-progress
                                [value]="thirdLevelData[col.field]"
                              ></app-progress>
                            </td>

                            <td *ngSwitchCase="'label'">
                              <app-label-table-field
                                severity="info"
                                [value]="thirdLevelData[col.field]"
                              ></app-label-table-field>
                            </td>

                            <td *ngSwitchCase="'boolean'">
                              <app-boolean-table-field
                                [value]="thirdLevelData[col.field]"
                              ></app-boolean-table-field>
                            </td>

                            <td *ngSwitchCase="'url'">
                              <app-url-table-field
                                [value]="thirdLevelData[col.field]"
                              ></app-url-table-field>
                            </td>
                          </ng-container>
                        </ng-container>
                      </tr>
                    </ng-template>
                  </p-table>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td
                  [attr.colspan]="columnsConfig.firstLevel.length + 1"
                  class="p-5 text-center"
                >
                  Aktualnie brak palet
                </td>
              </tr>
            </ng-template>
          </p-table>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
