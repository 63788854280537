import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-text-table-field',
  templateUrl: './text-table-field.component.html',
  styleUrl: './text-table-field.component.scss',
})
export class TextTableFieldComponent {
  @Input({ required: true }) value!: any;
}
