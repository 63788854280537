import { Injectable } from '@angular/core';
import { StorageKeys } from '../models/local-storage.model';
import { TTableStateNames } from '../../shared/models/tableStateNames';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() {}

  save(key: StorageKeys, value: any): void {
    try {
      const serializedData = JSON.stringify(value);
      localStorage.setItem(key, serializedData);
    } catch (err) {
      console.error('Error saving to localStorage', err);
    }
  }

  read<T>(key: StorageKeys): T | null {
    try {
      const serializedData = localStorage.getItem(key);
      return serializedData ? JSON.parse(serializedData) : null;
    } catch (err) {
      return null;
    }
  }

  remove(key: StorageKeys | TTableStateNames): void {
    try {
      localStorage.removeItem(key);
    } catch (err) {
      console.error('Error removing from localStorage', err);
    }
  }

  clear(): void {
    try {
      localStorage.clear();
    } catch (err) {
      console.error('Error clearing localStorage', err);
    }
  }
}
