<p-button
  (onClick)="showAddDialog()"
  label="Dodaj Materiał"
  class="add-material-btn"
/>
<p-dialog
  header="Dodawanie materiału"
  [modal]="true"
  [(visible)]="addDialogVisible"
  [style]="{ width: '25rem', 'min-height': '50rem' }"
  (onHide)="closeAddDialog()"
>
  <div class="form-input flex flex-column gap-2">
    <label for="material" class="font-semibold w-6rem">Materiał</label>
    <p-dropdown
      id="material"
      [options]="optionsToShow"
      [filter]="true"
      filterBy="name"
      optionLabel="name"
      [showClear]="true"
      placeholder="Wybierz materiał"
      [(ngModel)]="dialogSelectedOption"
    ></p-dropdown>
  </div>
  <div class="form-input flex flex-column gap-2">
    <label for="qty" class="font-semibold w-6rem">Ilość</label>
    <p-inputNumber inputId="qty" [(ngModel)]="dialogQtyValue" />
  </div>
  <div class="flex justify-content-end gap-2">
    <p-button
      label="Anuluj"
      severity="danger"
      (onClick)="addDialogVisible = false"
    />
    <p-button
      label="Dodaj"
      severity="success"
      (onClick)="addMaterial()"
      [disabled]="
        dialogQtyValue! <= 0 || !dialogQtyValue || !dialogSelectedOption
      "
    />
  </div>
</p-dialog>
<div class="elements-list-wrapper">
  <div class="element-wrapper" *ngFor="let element of currentElements">
    <div class="element-text-container">
      {{ element.name }} - {{ element.qty }}
    </div>
    <div class="element-btn-container">
      <p-button
        type="button"
        icon="pi pi-file-edit"
        styleClass="p-button-text p-button-custom"
        severity="info"
        (onClick)="showEditDialog(element)"
      ></p-button>
      <p-button
        (onClick)="deleteMaterial(element.id)"
        type="button"
        icon="pi pi-trash"
        styleClass="p-button-text p-button-custom"
        severity="danger"
      ></p-button>
    </div>
  </div>
</div>

<p-dialog
  header="Dodawanie materiału"
  [modal]="true"
  [(visible)]="editDialogVisible"
  [style]="{ width: '25rem', 'min-height': '50rem' }"
  (onHide)="closeAddDialog()"
  *ngIf="currentSelectedToEdit"
>
  <div class="form-input flex flex-column gap-2">
    <p>{{ currentSelectedToEdit.name }}</p>
  </div>
  <div class="form-input flex flex-column gap-2">
    <label for="qty" class="font-semibold w-6rem">Ilość</label>
    <p-inputNumber inputId="qty" [(ngModel)]="editDialogQtyValue" />
  </div>
  <div class="flex justify-content-end gap-2">
    <p-button
      label="Anuluj"
      severity="danger"
      (onClick)="editDialogVisible = false"
    />
    <p-button label="Dodaj" severity="success" (onClick)="editMaterial()" />
  </div>
</p-dialog>
