import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-pick-list-input',
  templateUrl: './pick-list-input.component.html',
  styleUrl: './pick-list-input.component.scss',
})
export class PickListInputComponent {
  @Input({ required: true }) options!: any[];
  @Input({ required: true }) controlName!: FormControl<any>;
  @Input({ required: true }) inputId!: string;
  @Input({ required: true }) label!: string;
  @Input({ required: true }) currentSelection!: any[];

  onMove(event: any): void {
    this.updateValue();
  }

  onReorder(event: any): void {
    this.updateValue();
  }
  updateValue() {
    this.controlName.setValue(
      this.currentSelection.map((element) => element.id)
    );
  }
}
