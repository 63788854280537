import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private messageService: MessageService) {}

  // showSuccess(summary?: string, detail: string) {
  //   this.showMessage('success', summary, detail);
  // }
  showSuccess(detail: string) {
    this.showMessage('success', detail);
  }
  showError(detail: string) {
    this.showMessage('error', detail);
  }

  showWarning(detail: string) {
    this.showMessage('warn', detail);
  }

  private showMessage(severity: string, detail: string) {
    this.messageService.add({ severity, detail, life: 3000 });
  }
}
