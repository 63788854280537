<div class="form-input flex flex-column gap-2">
  <label [for]="inputId">{{ label }}</label>
  <textarea
    [id]="inputId"
    rows="5"
    cols="30"
    pInputTextarea
    [formControl]="controlName"
  ></textarea>
</div>
