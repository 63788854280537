import {
  AfterContentInit,
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Table } from 'primeng/table';
import { LazyLoadEvent } from 'primeng/api';
import { actionFieldActionTypes } from '../../models/actionsTableFieldActionTypes.model';
import { TableFiltersService } from '../../../core/services/table.service';
import { IColumnConfig } from '../../../core/models/columnsConfig';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from '../../../core/services/local-storage.service';
import { TTableStateNames } from '../../models/tableStateNames';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss',
})
export class TableComponent implements AfterViewInit {
  @Input() statekey!: TTableStateNames;

  //sprawdzic czy to potrzebne przy swieżym logowaniu
  // @Input() sortBy!: string;
  // @Input() sortOrder!: string;
  ///
  @Input() withExportToExcell = true;
  @Input() otherActionsOnSelected = false;
  @Input() otherActionsBtnText = '';
  @Input() addNewLink: string = 'add';
  @Input() editLink: string = 'edit';
  @Input() deleteLink: string | string[] | null = null;
  @Input({ required: true }) tableData!: any[];
  @Input({}) startingSort: string | null = null;
  @Input() clickable: boolean = false;
  @Input({ required: true }) columnsConfig!: IColumnConfig[];
  @Input({ required: true }) filtersConfig!: any[];
  @Input() editableByUser: boolean = false;
  @Input() navigationLink: any = '';
  @Input({ required: true }) rows!: number;
  @Input({ required: true }) totalRecords!: number;
  @Output() changeSorting = new EventEmitter<any>();
  @Output() exportFile = new EventEmitter<void>();
  @Output() onDelete = new EventEmitter<{ target: EventTarget; id: number }>();
  @Output() getSelected = new EventEmitter<{
    target: EventTarget;
    id: number[];
  }>();
  @Output() otherActionOnSelectet = new EventEmitter<{
    target: EventTarget;
    ids: number[];
  }>();

  activeFilters: string = '0';
  @ViewChild('dt') dataTable!: Table;
  curentQuery: string | undefined;
  isTableInitialized = false;
  selectedRows: any[] = [];
  isFilterSidebarOpen = false;
  constructor(
    private tableFiltersService: TableFiltersService,
    private router: Router,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService
  ) {}

  ngAfterViewInit(): void {
    if (this.startingSort) {
      this.dataTable.sortField = this.startingSort;
      this.dataTable.sortOrder = -1;
    }
  }

  initFiltersOptions(filters: any): void {
    filters.forEach((filter: any) => {
      this.dataTable.filter(filter.startingValue, filter.fieldName, 'equals');
    });
  }

  loadWarehousesLazy(event: LazyLoadEvent) {
    const activeFilters = event.filters;
    if (activeFilters) {
      this.activeFilters = String(
        Object.keys(activeFilters)
          .map((key) => activeFilters[key].value)
          .filter((value) => value !== null).length
      );
    }

    if (!this.isTableInitialized) {
      this.isTableInitialized = true;
      return;
    }

    const queryParams = this.tableFiltersService.createQueryParams(
      event
      // this.sortBy,
      // this.sortOrder
    );
    this.changeSorting.emit(queryParams);
  }

  clear(table: Table) {
    table.clear();
    this.localStorageService.remove(this.statekey);
  }

  handleFilterChange(filterValue: any) {
    this.dataTable.filter(
      filterValue.value,
      filterValue.fieldName,
      filterValue.matchMode
    );
  }

  handleClickActions(
    target: EventTarget,
    id: number,
    value: actionFieldActionTypes
  ) {
    if ((value = 'delete')) {
      this.onDelete.emit({ target, id });
    }
  }
  redirectTo(id: number, event: any) {
    event.stopPropagation();
    const isActionClick = (event.target as HTMLElement).closest(
      '.actions-cell'
    );
    const isCheckbox = (event.target as HTMLElement).closest('.p-checkbox');
    const isCheckboxIcon = (event.target as HTMLElement).closest(
      '.p-checkbox-icon'
    );
    if (!isActionClick && !isCheckbox && !isCheckboxIcon) {
      this.router.navigate([`${this.navigationLink}/${id}`]);
    }
  }
  emitSelected(event: Event) {
    const target = event.target as EventTarget;
    const selectedRowsIds: number[] = this.selectedRows.map(
      (element: any) => element.id
    );
    this.getSelected.emit({ target, id: selectedRowsIds });
  }

  emitSelectedWithOtherAction(event: Event) {
    const target = event.target as EventTarget;
    const selectedRowsIds: number[] = this.selectedRows.map(
      (element: any) => element.id
    );
    this.otherActionOnSelectet.emit({ target, ids: selectedRowsIds });
  }
  onExport() {
    this.exportFile.emit();
  }

  clearSelected() {
    this.selectedRows = [];
    this.dataTable.selection = [];
    this.dataTable.saveState();
  }
  toggleFilterSidebar() {
    this.isFilterSidebarOpen = !this.isFilterSidebarOpen;
  }
}
