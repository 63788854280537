import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ILocationHistoryItem } from '../../../warehouse/models/locationHistoryItem';

// nie jest uniwersalne, jak będzie używane w kilku miejscach - dodać wrzucanie danych przez config na podstawie interface, jak nie to do usunięcia
@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrl: './timeline.component.scss',
})
export class TimelineComponent implements OnInit {
  @Input({ required: true }) events!: ILocationHistoryItem[];
  isFilterSidebarOpen = false;
  activeFilters = 0;

  @Output() changeFilters = new EventEmitter<any>();
  @Output() resetFilters = new EventEmitter();
  @Output() loadMore = new EventEmitter();

  filtersConfig = [
    {
      matchMode: 'equals',
      type: 'input',
    },
    {
      fieldName: 'date',
      type: 'date',
      label: 'Zakres dat',
      placeholder: 'Wybierz date',
    },
  ];
  ngOnInit(): void {}

  toggleFilterSidebar() {
    this.isFilterSidebarOpen = !this.isFilterSidebarOpen;
  }
  clear() {
    this.resetFilters.emit();
  }
  handleFilterChange(e: any) {
    const value = e.value.length === 0 ? null : e.value;
    this.changeFilters.emit({ [e.fieldName]: value });
  }
  onLoadMore() {
    this.loadMore.emit();
  }
}
