import { Component, Input, OnInit } from '@angular/core';

interface IIconTableFiledValue {
  icon: string;
  tooltip: string;
  class:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'info'
    | 'warning'
    | 'help'
    | 'danger';
}

@Component({
  selector: 'app-icon-table-field',
  templateUrl: './icon-table-field.component.html',
  styleUrl: './icon-table-field.component.scss',
})
export class IconTableFieldComponent {
  @Input({ required: true }) value!: IIconTableFiledValue;
}
