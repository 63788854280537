export enum PRODUCTION_Stands_API_ENDPOINTS {
  base = 'production-stands/',
  Export = base + 'export',
  List = base + 'list',
  Delete = base + 'delete',
  Add = base + 'store',
  Edit = base + 'edit/',
  GetEditData = base + 'get-data',
  Get = base + 'get',
}
