<div class="form-input flex flex-column gap-2">
  <label [for]="inputId">{{ label }}</label>
  <p-multiSelect
    [options]="options"
    [id]="inputId"
    [formControl]="controlName"
    optionLabel="name"
    optionValue="id"
    [placeholder]="placeholder"
    selectedItemsLabel="Wybrane elementy: {0}"
  />
</div>
