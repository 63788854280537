<div class="text-center">
  <i
    [ngClass]="[class, icon]"
    tooltipPosition="bottom"
    [pTooltip]="tooltip"
  ></i>
</div>
<ng-template #tooltipContent>
  <div class="flex align-items-center justify-content-center">
    {{ tooltip }}
  </div>
</ng-template>
