import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IPackagingGroupsFormElementResponsePackagingGroupItem } from '../../../../administration/models/packagingGroupsElementResponsePackagingGroupItem';

interface ICustomPackagingGroupElementsInputOption {
  name: string;
  id: number;
  qty: number;
}

@Component({
  selector: 'app-custom-packaging-groups-elements-input',
  templateUrl: './custom-packaging-groups-elements-input.component.html',
  styleUrl: './custom-packaging-groups-elements-input.component.scss',
})
export class CustomPackagingGroupsElementsInputComponent implements OnInit {
  @Input({ required: true }) controlName!: FormControl<any>;
  @Input({ required: true })
  options!: ICustomPackagingGroupElementsInputOption[];
  @Input({ required: true }) inputId!: string;
  @Input({ required: true }) label!: string;
  @Input({ required: true }) packagingGroupId!: string | null;
  @Input({ required: true })
  currentElements!: ICustomPackagingGroupElementsInputOption[];
  @Input() placeholder: string = 'Wybierz opcję';

  optionsToShow: ICustomPackagingGroupElementsInputOption[] = [];
  //dialog add
  addDialogVisible: boolean = false;
  dialogQtyValue: number | undefined;
  dialogSelectedOption: ICustomPackagingGroupElementsInputOption | undefined;
  //dialog edit
  editDialogVisible: boolean = false;
  editDialogQtyValue: number | undefined;
  currentSelectedToEdit: ICustomPackagingGroupElementsInputOption | null = null;

  ngOnInit(): void {
    this.optionsToShow = this.updateOptionsToShow();
  }

  showAddDialog() {
    this.addDialogVisible = true;
  }

  closeAddDialog() {
    this.addDialogVisible = false;
    this.resetAddDialogInputs();
  }

  resetAddDialogInputs() {
    this.dialogQtyValue = undefined;
    this.dialogSelectedOption = undefined;
  }

  addMaterial() {
    const materialToAdd = {
      name: this.dialogSelectedOption!.name,
      id: this.dialogSelectedOption!.id,
      qty: this.dialogQtyValue!,
    };

    this.currentElements = [...this.currentElements, materialToAdd];
    this.optionsToShow = this.updateOptionsToShow();
    this.setControlValue();
    this.addDialogVisible = false;
  }

  updateOptionsToShow() {
    return this.options.filter(
      (option) =>
        !this.currentElements.some(
          (currentElementsItem) => currentElementsItem.id === option.id
        )
    );
  }

  setControlValue() {
    const value = this.currentElements.map((element) => {
      const baseValue = {
        package_id: element.id,
        qty: element.qty,
      };

      if (this.packagingGroupId) {
        return {
          ...baseValue,
          id: Number(this.packagingGroupId),
        };
      }

      return baseValue;
    });

    this.controlName.setValue(value);
  }

  deleteMaterial(id: number) {
    this.currentElements = this.currentElements.filter(
      (element) => element.id !== id
    );
    this.optionsToShow = this.updateOptionsToShow();
    this.setControlValue();
  }

  showEditDialog(element: ICustomPackagingGroupElementsInputOption) {
    this.currentSelectedToEdit = element;
    this.editDialogQtyValue = element.qty;
    this.editDialogVisible = true;
  }

  closeEditDialog() {
    this.editDialogVisible = false;
    this.resetEditDialog();
  }
  resetEditDialog() {
    this.currentSelectedToEdit = null;
    this.editDialogQtyValue = undefined;
  }

  //TODO: przerobić na pure ?
  editMaterial() {
    this.currentElements = this.currentElements.map((element) => {
      if (element.id === this.currentSelectedToEdit?.id) {
        return { ...element, qty: this.editDialogQtyValue! };
      }
      return element;
    });
    this.setControlValue();
    this.editDialogVisible = false;
  }
}
