import { Component, Input, ViewChild } from '@angular/core';
import { IColumnConfig } from '../../../core/models/columnsConfig';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-table-simplified-filters',
  templateUrl: './table-simplified-filters.component.html',
  styleUrl: './table-simplified-filters.component.scss',
})
export class TableSimplifiedFiltersComponent {
  @Input({ required: true }) data!: any[];
  @Input({ required: true }) columnsConfig!: IColumnConfig[];

  @Input({ required: true }) filtersConfig!: any[];
  isFilterSidebarOpen = false;
  activeFilters: string = '0';

  @ViewChild('dt') dataTable!: Table;

  initFiltersOptions(filters: any): void {
    filters.forEach((filter: any) => {
      this.dataTable.filter(filter.startingValue, filter.fieldName, 'equals');
    });
  }
  handleFilterChange(filterValue: any) {
    if (filterValue.fieldName === 'query') {
      this.dataTable.filterGlobal(filterValue.value, 'contains');
      return;
    }
    this.dataTable.filter(
      filterValue.value,
      filterValue.fieldName,
      filterValue.matchMode
    );
  }
  clear(table: Table) {
    table.clear();
  }

  toggleFilterSidebar() {
    this.isFilterSidebarOpen = !this.isFilterSidebarOpen;
  }
}
