<p-button
  (onClick)="showAddDialog()"
  label="Dodaj zasób"
  class="add-material-btn"
/>
<p-dialog
  header="Dodaj do receptury"
  [modal]="true"
  [(visible)]="addDialogVisible"
  [style]="{ width: '25rem', 'min-height': '50rem' }"
  (onHide)="closeAddDialog()"
>
  <div class="form-input flex flex-column gap-2">
    <label for="material" class="font-semibold w-6rem">Zasób</label>
    <p-autoComplete
      [(ngModel)]="selectedOption"
      (completeMethod)="search($event)"
      [suggestions]="suggestions"
      [dropdown]="true"
      (onSelect)="onSelect()"
      field="name"
    ></p-autoComplete>
    <small class="error" *ngIf="isSelectedOptionInRecipe"
      >Wybrany zasób jest już częścią receptury</small
    >
  </div>
  <div class="form-input flex flex-column gap-2">
    <label for="qty" class="font-semibold w-6rem">Ilość</label>
    <p-inputNumber inputId="qty" [(ngModel)]="dialogQtyValue" />
  </div>
  <div class="form-input flex flex-column gap-2">
    <label for="qty" class="font-semibold w-6rem">Notatka</label>
    <textarea
      rows="5"
      cols="30"
      pInputTextarea
      [(ngModel)]="dialogTextAreaValue"
    ></textarea>
  </div>

  <div class="flex justify-content-end gap-2">
    <p-button
      label="Anuluj"
      severity="danger"
      (onClick)="addDialogVisible = false"
    />
    <p-button
      label="Dodaj"
      severity="success"
      (onClick)="addElementToRecipe()"
      [disabled]="
        dialogQtyValue! <= 0 ||
        !dialogQtyValue ||
        !selectedOption ||
        isSelectedOptionInRecipe
      "
    />
  </div>
</p-dialog>
<div class="elements-list-wrapper">
  <div
    class="element-wrapper card-item-border"
    *ngFor="let element of currentRecipeElements"
  >
    <div class="info-area">
      <h3 class="element-text-container">{{ element.name }}</h3>
      <div class="element-text-container">Ilość: {{ element.qty }}</div>
      <div class="element-note-container">
        {{ element.note }}
      </div>
    </div>

    <div class="element-btn-container">
      <p-button
        type="button"
        icon="pi pi-file-edit"
        styleClass="p-button-text p-button-custom"
        severity="info"
        (onClick)="showEditDialog(element)"
      ></p-button>
      <p-button
        (onClick)="deleteMaterial(element.component_item_id)"
        type="button"
        icon="pi pi-trash"
        styleClass="p-button-text p-button-custom"
        severity="danger"
      ></p-button>
    </div>
  </div>
</div>

<p-dialog
  header="Edycja materiału"
  [modal]="true"
  [(visible)]="editDialogVisible"
  [style]="{ width: '25rem', 'min-height': '50rem' }"
  (onHide)="closeAddDialog()"
  *ngIf="currentSelectedToEdit"
>
  <div class="form-input flex flex-column gap-2">
    <label for="qty" class="font-semibold w-6rem">Zasób</label>

    <p>{{ currentSelectedToEdit.name }}</p>
  </div>
  <div class="form-input flex flex-column gap-2">
    <label for="qty" class="font-semibold w-6rem">Ilość</label>
    <p-inputNumber inputId="qty" [(ngModel)]="editDialogQtyValue" />
  </div>
  <div class="form-input flex flex-column gap-2">
    <label for="qty" class="font-semibold w-6rem">Notatka</label>
    <textarea
      rows="5"
      cols="30"
      pInputTextarea
      [(ngModel)]="editDialogTextAreaValue"
    ></textarea>
  </div>
  <div class="flex justify-content-end gap-2">
    <p-button
      label="Anuluj"
      severity="danger"
      (onClick)="editDialogVisible = false"
    />
    <p-button label="Zapisz" severity="success" (onClick)="editMaterial()" />
  </div>
</p-dialog>
