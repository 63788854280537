import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-dropdown-input',
  templateUrl: './dropdown-input.component.html',
  styleUrl: './dropdown-input.component.scss',
})
export class DropdownInputComponent {
  @Input({ required: true }) controlName!: FormControl<any>;
  @Input({ required: true }) options!: [name: string, id: string | number];
  @Input({ required: true }) inputId!: string;
  @Input({ required: true }) label!: string;
  @Input() placeholder: string = 'Wybierz opcję';
}
