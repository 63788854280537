<p-table
  *ngIf="data"
  [value]="data"
  [columns]="columnsConfig"
  styleClass="p-datatable-gridlines"
  [resizableColumns]="true"
>
  <ng-template pTemplate="header">
    <tr>
      <th pResizableColumn class="first-table-header">LP.</th>
      <ng-container *ngFor="let col of columnsConfig">
        <th
          pResizableColumn
          [pSortableColumn]="col.sortable ? col.field : undefined"
        >
          {{ col.header }}
          <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
        </th>
      </ng-container>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item let-i="rowIndex">
    <tr>
      <td>{{ i + 1 }}</td>
      <ng-container *ngFor="let col of columnsConfig">
        <ng-container [ngSwitch]="col.type">
          <td *ngSwitchCase="'text'">
            <app-text-table-field
              [value]="item[col.field]"
            ></app-text-table-field>
          </td>
          <td *ngSwitchCase="'text-notification'">
            <app-text-with-notification-table-field
              [element]="item"
              [fields]="col.notifications!"
              [mainField]="col.field"
            ></app-text-with-notification-table-field>
          </td>
          <td *ngSwitchCase="'decimal'">
            <app-decimal-table-field
              [value]="item[col.field]"
            ></app-decimal-table-field>
          </td>
          <td *ngSwitchCase="'decimal-comparison-color'">
            <app-custom-decimal-comparison-color
              [value]="item[col.field]"
              [compareValue]="item[col.compareValue!]"
            ></app-custom-decimal-comparison-color>
          </td>
          <td *ngSwitchCase="'icon'">
            <app-icon-table-field
              color="red"
              icon="pi pi plus"
              tooltip="test"
              [value]="item[col.field]"
            ></app-icon-table-field>
          </td>
          <td *ngSwitchCase="'progress'">
            <app-progress [value]="item[col.field]"></app-progress>
          </td>

          <td *ngSwitchCase="'label'">
            <app-label-table-field
              severity="info"
              [value]="item[col.field]"
            ></app-label-table-field>
          </td>

          <td *ngSwitchCase="'boolean'">
            <app-boolean-table-field
              [value]="item[col.field]"
            ></app-boolean-table-field>
          </td>

          <td *ngSwitchCase="'url'">
            <app-url-table-field
              [value]="item[col.field]"
            ></app-url-table-field>
          </td>
        </ng-container>
      </ng-container>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [attr.colspan]="columnsConfig.length + 2" class="p-5 text-center">
        Brak rekordów
      </td>
    </tr>
  </ng-template>
</p-table>
