import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { actionFieldActionTypes } from '../../../models/actionsTableFieldActionTypes.model';

interface IactionsValue {
  can_edit: boolean;
  can_delete: boolean;
}

@Component({
  selector: 'app-actions-table-field',
  templateUrl: './actions-table-field.component.html',
  styleUrl: './actions-table-field.component.scss',
})
export class ActionsTableFieldComponent implements OnInit {
  @Input({ required: true }) actions!: IactionsValue;
  @Input({ required: true }) id!: number;
  @Input() editLink: string | string[] | null = null;
  @Output() onClick = new EventEmitter<{
    target: EventTarget;
    id: number;
    actionType: actionFieldActionTypes;
  }>();
  editLinkWithId!: string;
  ngOnInit(): void {
    this.actionsArr = this.createActionsArray();
    this.editLinkWithId = `${this.editLink}/${this.id}`;
  }
  actionsArr!: Array<{ type: string; value: boolean }>;

  handleClick(event: Event, id: number, actionType: actionFieldActionTypes) {
    const target = event.target as EventTarget;
    this.onClick.emit({ target, id, actionType });
  }

  createActionsArray(): Array<{ type: string; value: boolean }> {
    return Object.entries(this.actions).map(([key, value]) => ({
      type: key,
      value: value,
    }));
  }
}
