import { Component, Input, OnInit } from '@angular/core';
import { IOrderItemsGroupedByPallets } from '../../../orders/models/orderItemsGroupedByPallets';

// @TODO: przekazywać dane dynamicznie

@Component({
  selector: 'app-expansion-table',
  templateUrl: './expansion-table.component.html',
  styleUrl: './expansion-table.component.scss',
})
export class ExpansionTableComponent implements OnInit {
  @Input({ required: true }) data!: any[];

  // dataTest: any[];
  expandedRows: { [key: string]: boolean } = {};
  expandedSecoundLevelRows: { [key: string]: boolean } = {};
  calculatedRowSpan!: number;
  columnsConfig: any;
  dataKeys = {
    secoundLevel: 'pallets',
    thirdLevel: 'elements',
  };
  constructor() {
    // this.dataTest = [
    //   {
    //     id: 1,
    //     name: 'Przedmiot nr. 1',
    //     qty: 20,
    //     code: '123',
    //     pallets: [
    //       {
    //         id: 41,
    //         name: 'Paleta1',
    //         date: '1.1.2024',
    //         items: [
    //           { item: 'item1', qty: 20 },
    //           { item: 'item2', qty: 10 },
    //           { item: 'item3', qty: 30 },
    //         ],
    //       },
    //       {
    //         id: 32,
    //         name: 'Paleta2',
    //         date: '1.2.2024',
    //         items: [{ item: 'item2', qty: 20 }],
    //       },
    //     ],
    //   },
    //   {
    //     id: 2,
    //     name: 'Przedmiot nr. 2',
    //     qty: 120,
    //     code: '12333',
    //     pallets: [
    //       {
    //         id: 15,
    //         name: 'Paleta3',
    //         date: '1.1.2024',
    //         items: [
    //           { item: 'item1', qty: 20 },
    //           { item: 'item2', qty: 10 },
    //           { item: 'item2', qty: 30 },
    //         ],
    //       },
    //       {
    //         id: 17,
    //         name: 'Paleta4',
    //         date: '1.2.2024',
    //         items: [{ item: 'item4', qty: 20 }],
    //       },
    //     ],
    //   },
    //   {
    //     id: 3,
    //     name: 'Przedmiot nr. 3',
    //     qty: 120,
    //     code: '12333',
    //     pallets: [
    //       {
    //         id: 235,
    //         name: 'Paleta3',
    //         date: '1.1.2024',
    //         items: [
    //           { item: 'item1', qty: 20 },
    //           { item: 'item2', qty: 10 },
    //           { item: 'item2', qty: 30 },
    //         ],
    //       },
    //       {
    //         id: 4127,
    //         name: 'Paleta4',
    //         date: '1.2.2024',
    //         items: [{ item: 'item3213', qty: 20 }],
    //       },
    //       {
    //         id: 112,
    //         name: 'Paleta3332',
    //         date: '1.2.2024',
    //         items: [{ item: 'item232', qty: 20 }],
    //       },
    //       {
    //         id: 342,
    //         name: 'Paleta213123',
    //         date: '1.2.2024',
    //         items: [{ item: 'item42', qty: 20 }],
    //       },
    //     ],
    //   },
    // ];
  }
  ngOnInit(): void {
    this.columnsConfig = {
      levelNames: {
        first: 'Towary',
        secound: 'Palety',
        third: 'Partie',
      },
      firstLevel: [
        {
          field: 'itemName',
          header: 'Nazwa',
          sortable: false,
          type: 'text',
        },
        {
          field: 'itemUnitName',
          header: 'J.M',
          sortable: false,
          type: 'text',
        },
        {
          field: 'ItemQty',
          header: 'Ilość zamówiona',
          sortable: false,
          type: 'decimal',
        },
        {
          field: 'ItemQtyPacked',
          header: 'Ilość spakowana',
          sortable: false,
          type: 'decimal',
        },
        {
          field: 'ItemQtyRealized',
          header: 'Ilość wysłana',
          sortable: false,
          type: 'decimal',
        },
      ],
      secoundLevel: [
        {
          field: 'palletNumber',
          header: 'Numer Palety',
          sortable: false,
          type: 'text',
        },
        {
          field: 'palletBarcode',
          header: 'Kod',
          sortable: false,
          type: 'text',
        },
        {
          field: 'isRealized',
          header: 'Wysłana',
          sortable: false,
          type: 'boolean',
        },
        {
          field: 'realizedDate',
          header: 'Data wysyłki',
          sortable: false,
          type: 'text',
        },
      ],
      thirdLevel: [
        {
          field: 'barcode',
          header: 'Kod',
          sortable: false,
          type: 'text',
        },
        {
          field: 'qty',
          header: 'Ilość',
          sortable: false,
          type: 'decimal',
        },
        {
          field: 'addDate',
          header: 'Data dodania',
          sortable: false,
          type: 'text',
        },
      ],
    };
    // for expanded tables, calculate first table columns for 100% widht
    this.calculatedRowSpan = this.columnsConfig.firstLevel.length + 1;
  }

  isPalletsVisible(row: any) {
    return this.expandedRows[row.temporaryId];
  }

  toggleRow(row: any) {
    if (this.expandedRows[row.temporaryId]) {
      this.collapseNestedRows(row.pallets);
    }
    this.expandedRows[row.temporaryId] = !this.expandedRows[row.temporaryId];

    console.log(this.expandedRows);
  }

  isItemsVisible(pallet: any) {
    return this.expandedSecoundLevelRows[pallet.temporaryId];
  }

  togglePalletRow(pallet: any) {
    this.expandedSecoundLevelRows[pallet.temporaryId] =
      !this.expandedSecoundLevelRows[pallet.temporaryId];
  }

  collapseNestedRows(pallets: any[]) {
    pallets.forEach((pallet) => {
      if (this.expandedSecoundLevelRows[pallet.temporaryId]) {
        this.expandedSecoundLevelRows[pallet.temporaryId] = false;
      }
    });
  }
}
