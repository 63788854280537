import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IProductionLine } from '../../../../production/models/productionLine';
import { IProductionStand } from '../../../../production/models/productionStand';
import { PredefinedDataService } from '../../../../core/services/predefined-data.service';
import { NotificationService } from '../../../../core/services/notification.service';
import {
  IProductionStepsElement,
  IProductionStepsElementWithProductionInfo,
} from '../../../../resources/models/productionStepsElement';
import { IProductionStepsElementResponse } from '../../../../resources/models/productionStepElementResponse';

@Component({
  selector: 'app-custom-production-steps-input',
  templateUrl: './custom-production-steps-input.component.html',
  styleUrl: './custom-production-steps-input.component.scss',
})
export class CustomProductionStepsInputComponent {
  @Input({ required: true }) controlName!: FormControl<any>;
  @Input({ required: true })
  passedCurrentProductionStepsElements!: IProductionStepsElementResponse[];
  optionsContext: 'production-line' | 'production-stand' | undefined =
    undefined;
  addDialogVisible: boolean = false;
  checkAging = false;
  editCheckAging = false;
  dialogName = '';
  editDialogName = '';
  dialogTextAreaValue = '';
  dialogAgingValue: number | undefined = undefined;
  editDialogTextAreaValue = '';
  editDialogAgingValue: number | undefined = undefined;
  productionLinesOptions!: IProductionLine[];
  productionStandsOptions!: IProductionStand[];
  dialogSelectedOption: IProductionLine | IProductionStand | null = null;
  currentProductionStepsElements: IProductionStepsElementWithProductionInfo[] =
    [];
  currentSelectedToEdit: IProductionStepsElementWithProductionInfo | null =
    null;
  editDialogVisible: boolean = false;
  constructor(
    private predefinedDataService: PredefinedDataService,
    private notificationService: NotificationService
  ) {}
  ngOnInit(): void {
    if (this.passedCurrentProductionStepsElements.length) {
      this.currentProductionStepsElements =
        this.passedCurrentProductionStepsElements.map((element) => {
          return {
            name: element.name,
            note: element.note,
            prod_info: element.prod_info,
            production_group_id: element.production_group_id,
            production_stand_id: element.production_stand_id,
            shelf_time: element.shelf_time,
            temporaryId: this.generateUUID(),
          };
        });
    }
    this.predefinedDataService.getProductionStandsList().subscribe({
      next: (data) => {
        this.productionStandsOptions = data.data;
      },
      error: (err) => {
        this.notificationService.showError(
          'Wystąpił błąd przy pobieraniu danych'
        );
      },
    });
    this.predefinedDataService.getProductionLinesList().subscribe({
      next: (data) => {
        this.productionLinesOptions = data.data;
      },
      error: (err) => {
        this.notificationService.showError(
          'Wystąpił błąd przy pobieraniu danych'
        );
      },
    });
  }
  addToProductionSteps() {
    let materialToAdd;
    if (this.optionsContext === 'production-line') {
      materialToAdd = {
        name: this.dialogName,
        shelf_time: this.dialogAgingValue ?? 0,
        production_group_id: this.dialogSelectedOption!.id,
        production_stand_id: null,
        note: this.dialogTextAreaValue,
        prod_info: this.dialogSelectedOption?.name ?? '',
        temporaryId: this.generateUUID(),
      };
    } else {
      materialToAdd = {
        name: this.dialogName,
        shelf_time: this.dialogAgingValue ?? 0,
        note: this.dialogTextAreaValue,
        production_stand_id: this.dialogSelectedOption!.id,
        production_group_id: null,
        prod_info: this.dialogSelectedOption?.name ?? '',
        temporaryId: this.generateUUID(),
      };
    }
    this.currentProductionStepsElements = [
      ...this.currentProductionStepsElements,
      materialToAdd,
    ];
    this.setControlValue();
    this.closeAddDialog();
  }
  showAddDialog(type: 'production-line' | 'production-stand') {
    this.optionsContext = type;
    this.addDialogVisible = true;
  }
  closeAddDialog() {
    this.addDialogVisible = false;
    this.resetAddDialogInputs();
  }
  deleteMaterial(elementToDelete: IProductionStepsElement) {
    this.currentProductionStepsElements =
      this.currentProductionStepsElements.filter((element) => {
        return element.temporaryId !== elementToDelete.temporaryId;
      });

    this.setControlValue();
  }
  showEditDialog(element: IProductionStepsElementWithProductionInfo) {
    'production_group_id' in element
      ? (this.optionsContext = 'production-line')
      : (this.optionsContext = 'production-stand');
    this.currentSelectedToEdit = element;
    this.editDialogTextAreaValue = element.note ?? '';
    this.editDialogVisible = true;
    this.editDialogName = element.name;
    this.editDialogAgingValue = element.shelf_time;
    this.editCheckAging = element.shelf_time ? true : false;
  }
  editProductionStep() {
    this.currentProductionStepsElements =
      this.currentProductionStepsElements.map((element) => {
        if (element.temporaryId === this.currentSelectedToEdit!.temporaryId) {
          return {
            ...element,
            note: this.editDialogTextAreaValue,
            name: this.editDialogName,
            shelf_time: this.editCheckAging ? this.editDialogAgingValue! : 0,
          };
        } else {
          return element;
        }
      });
    this.setControlValue();
    this.editDialogVisible = false;
    this.resetEditDialogValues();
  }
  setControlValue() {
    const value = this.currentProductionStepsElements.map((element) => {
      return {
        name: element.name,
        shelf_time: element.shelf_time ?? null,
        production_group_id: element.production_group_id,
        production_stand_id: element.production_stand_id,
        note: element.note,
      };
    });
    this.controlName.setValue(value);
  }
  resetAddDialogInputs() {
    this.dialogSelectedOption = null;
    this.dialogTextAreaValue = '';
    this.dialogName = '';
    this.dialogAgingValue = undefined;
  }
  resetEditDialogValues() {
    this.currentSelectedToEdit = null;
    this.editDialogTextAreaValue = '';
    this.editDialogName = '';
    this.editDialogAgingValue = undefined;
  }

  generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }
}
