export enum USERS_API_ENDPOINTS {
  base = 'users/',
  Export = base + 'export',
  List = base + 'list',
  Roles = base + 'role-list',
  Add = base + 'store',
  Delete = base + 'delete',
  GetUser = base + 'get/',
  Edit = base + 'edit/',
}
