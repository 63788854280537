<ng-container>
  <i
    class="flex align-items-center justify-content-center pi"
    [ngClass]="[value.class, value.icon]"
    tooltipPosition="bottom"
    [pTooltip]="value.tooltip"
  ></i>
</ng-container>
<ng-template #tooltipContent>
  <div class="flex align-items-center justify-content-center">
    {{ value.tooltip }}
  </div>
</ng-template>
