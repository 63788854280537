<p class="text-table-field">
  {{ value }}
  <span class="ico-container" *ngIf="notification.length > 0">
    <i
      class="pi pi-exclamation-circle"
      tooltipPosition="bottom"
      [pTooltip]="notification"
    ></i
  ></span>
</p>
