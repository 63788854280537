<p-dialog
  [header]="title"
  [modal]="true"
  [(visible)]="show"
  [style]="{ width: '25rem' }"
  (onHide)="notifyHide()"
  [dismissableMask]="true"
>
  <ul class="dialog-list" *ngIf="list.length > 0; else noItemsTemplate">
    <li class="dialog-list--element" *ngFor="let element of list">
      {{ element.name }}
    </li>
  </ul>
  <ng-template #noItemsTemplate>
    <h3 class="no-items-text">Brak</h3>
  </ng-template>
</p-dialog>
