<div class="action-buttons-wrapper">
  <ng-container *ngFor="let action of actionsArr">
    <div class="btn-container" [ngSwitch]="action.type">
      <p-button
        *ngSwitchCase="'can_edit'"
        type="button"
        icon="pi pi-file-edit"
        styleClass="p-button-text"
        severity="info"
        [routerLink]="editLinkWithId"
        [disabled]="!action.value"
      ></p-button>
      <p-button
        *ngSwitchCase="'can_delete'"
        type="button"
        icon="pi pi-trash"
        styleClass="p-button-text"
        severity="danger"
        (onClick)="handleClick($event, id, 'delete')"
        [disabled]="!action.value"
      ></p-button>
    </div>
  </ng-container>
</div>
