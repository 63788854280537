<p-calendar
  [(ngModel)]="dates"
  selectionMode="range"
  [readonlyInput]="true"
  [placeholder]="placeholder"
  (onClose)="onChange()"
  dateFormat="dd.mm.yy"
  [showClear]="true"
  (onClear)="onChange()"
></p-calendar>
