import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-multi-switch-input',
  templateUrl: './multi-switch-input.component.html',
  styleUrl: './multi-switch-input.component.scss',
})
export class MultiSwitchInputComponent implements OnInit {
  @Input({ required: true }) options!: any[];
  @Input({ required: true }) controlName!: FormControl<any>;
  @Input({ required: true }) inputId!: string;
  @Input({ required: true }) label!: string;
  @Input({ required: true }) currentSelection!: any[];
  switchStates: { [key: string]: boolean } = {};

  constructor() {}
  ngOnInit(): void {
    this.initializeSwitchStates();
    this.controlName.setValue(this.currentSelection);
  }

  initializeSwitchStates(): void {
    this.options.forEach((option) => {
      this.switchStates[option.id] = this.currentSelection.includes(option.id);
    });
  }
  onSwitchChange(id: any, checked: boolean): void {
    if (checked) {
      if (!this.currentSelection.includes(id)) {
        this.currentSelection.push(id);
      }
    } else {
      this.currentSelection = this.currentSelection.filter(
        (element) => element !== id
      );
    }
    this.updateValue();
  }

  updateValue() {
    this.controlName.setValue(this.currentSelection);
  }
}
