import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-number-input',
  templateUrl: './number-input.component.html',
  styleUrl: './number-input.component.scss',
})
export class NumberInputComponent {
  @Input({ required: true }) controlName!: FormControl<any>;
  @Input({ required: true }) label!: string;
  @Input({ required: true }) inputId!: string;
}
