import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-input-filter',
  templateUrl: './input-filter.component.html',
  styleUrl: './input-filter.component.scss',
})
export class InputFilterComponent implements OnInit {
  private _clear = false;
  @Input() value: string | null = null;
  @Input() set clear(value: boolean) {
    if (value === true) {
      this.onClear();
    }
    this._clear = value;
  }
  @Output() changeSorting = new EventEmitter<string>();
  private inputSubject = new Subject<string>();

  ngOnInit() {
    this.inputSubject
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((value) => {
        this.changeSorting.emit(value);
      });
  }

  ngOnDestroy() {
    this.inputSubject.unsubscribe();
  }

  onInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.inputSubject.next(inputElement.value);
  }
  onClear() {
    this.value = null;
  }
}
