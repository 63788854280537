import { Injectable } from '@angular/core';
import { FilterMetadata, LazyLoadEvent } from 'primeng/api';
import { ISortOptions } from '../models/sort-options.model';

@Injectable({
  providedIn: 'root',
})
export class TableFiltersService {
  constructor() {}

  createQueryParams(
    event: LazyLoadEvent
    // defaultSortBy: string,
    // defaultOrder: string
  ) {
    return {
      filters: this.createFilters(event),
      sortOptions: this.createSortOptions(event),
      // sortOptions: this.createSortOptions(event, defaultSortBy, defaultOrder),
    };
  }

  private createFilters(event: LazyLoadEvent) {
    const filters: { [key: string]: any } = {};
    const filtersFromEvent: {
      [s: string]: FilterMetadata;
    } = event.filters || {};

    for (const key in filtersFromEvent) {
      const filter = filtersFromEvent[key];
      if (Array.isArray(filter)) {
        filters[key] = filter.map((f) => f.value);
      } else {
        filters[key] = filter.value;
      }
    }

    return filters;
  }

  private createSortOptions(
    event: LazyLoadEvent
    // defaultSortBy: string,
    // defaultOrder: string
  ): ISortOptions {
    return {
      page: event.first! / event.rows! + 1,
      limit: event.rows!,
      sort: event.sortField!,
      order: event.sortOrder === 1 ? 'asc' : 'desc',
    };
  }
}
