import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ApiService } from '../../../../core/services/api.service';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import {
  BehaviorSubject,
  Subject,
  Subscription,
  debounceTime,
  switchMap,
} from 'rxjs';

interface DataResponse {
  data: any[];
}

interface Item {
  id: number;
  name: string;
}

@Component({
  selector: 'app-autocomplete-filter',
  templateUrl: './autocomplete-filter.component.html',
  styleUrls: ['./autocomplete-filter.component.scss'],
})
export class AutocompleteFilterComponent implements OnInit, OnDestroy {
  private searchSubscription = new Subscription();

  @Input() startValue: number | null = null;
  @Input({ required: true }) url!: string;
  @Input() placeholder!: string;
  @Input() set clear(value: boolean) {
    if (value === true) {
      this.onClear();
    }
    this._clear = value;
  }
  private _clear = false;
  @Output() changeSorting = new EventEmitter<number>();
  searchTerms = new Subject<string>();
  selectedOption: Item | null = null;
  suggestions: Item[] = [];

  constructor(private apiService: ApiService) {}

  ngOnInit(): void {
    if (this.startValue) {
      this.loadInitialData();
    }

    this.searchSubscription = this.searchTerms
      .pipe(
        debounceTime(300),
        switchMap((term) =>
          this.apiService.get<DataResponse>(this.url, { query: term })
        )
      )
      .subscribe({
        next: (data) => (this.suggestions = data.data),
      });
  }

  loadInitialData() {
    this.apiService
      .get<DataResponse>(this.url, { id: this.startValue })
      .subscribe({
        next: (response) => {
          if (response.data.length > 0) {
            this.selectedOption = response.data[0];
          }
        },
        error: () => console.error('Błąd przy pobieraniu zasobów'),
      });
  }

  search(event: AutoCompleteCompleteEvent) {
    this.searchTerms.next(event.query);
  }

  onClear() {
    this.selectedOption = null;
  }

  onSelect() {
    this.changeSorting.emit(this.selectedOption?.id);
  }

  ngOnDestroy(): void {
    this.searchSubscription.unsubscribe();
  }
}
