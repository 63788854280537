import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-status-field',
  templateUrl: './icon-status-field.component.html',
  styleUrl: './icon-status-field.component.scss',
})

//dla potrzeb testu -- do przerobienia cały komponent
export class IconStatusFieldComponent implements OnInit {
  icon = 'pi pi-cog';
  class = '';
  tooltip = 'test';
  ngOnInit(): void {
    switch (this.value) {
      case 'W użyciu':
        this.class = 'pi-spin text-2xl text--active';
        this.tooltip = 'aktywna';
        break;

      default:
        return;
    }
  }

  @Input({ required: true }) value!: string;
}
