import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-custom-decimal-comparison-color',
  templateUrl: './custom-decimal-comparison-color.component.html',
  styleUrl: './custom-decimal-comparison-color.component.scss',
})
export class CustomDecimalComparisonColorComponent {
  class: string = '';
  @Input({ required: true }) value!: number;
  @Input({ required: true }) compareValue!: number;
  ngOnInit(): void {
    Number(this.value) >= Number(this.compareValue)
      ? (this.class = 'green')
      : (this.class = 'red');
  }
}
