<div
  class="photos-wrapper"
  *ngIf="dataPhotos && dataPhotos.length; else noPhotos"
>
  <ng-container *ngFor="let photo of dataPhotos">
    <div class="photo-container">
      <p-image [src]="photo.path" alt="Image" width="250" [preview]="true" />
      <div class="photo-info-container">
        <h3 class="header">{{ photo.elementBatchNumber }}</h3>
        <p>{{ photo.elementName }}</p>
        <div *ngIf="photo.isComplaint" class="complain-badge">
          <p-tag severity="danger" value="Reklamacja" />
        </div>
        <p>{{ photo.date }}</p>
      </div>
    </div>
  </ng-container>
</div>
<ng-template #noPhotos>
  <h3>Brak zdjęć przypisanych do dokumentu</h3>
</ng-template>
