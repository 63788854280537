<div class="form-input flex flex-column gap-2">
  <label [for]="inputId">{{ label }}</label>
  <p-calendar
    [(ngModel)]="date"
    [readonlyInput]="true"
    [placeholder]="placeholder"
    dateFormat="dd.mm.yy"
    [showClear]="true"
    (onSelect)="onChange()"
    (onClear)="onClear()"
  ></p-calendar>
</div>
