import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NotificationService } from '../../../core/services/notification.service';
import { CustomValidationError } from '../../../../utils/CustomValidationError';
import { Router } from '@angular/router';
import { IOnSubmitConfig } from '../../../core/models/onSubmitConfig';
import { IOnSubmitConfigEdit } from '../../../core/models/onSubmitConfigEdit';
import { IOnSubmitConfigAdd } from '../../../core/models/onSubmitConfigAdd';

// the id field must refer to the appropriate control in the FormGroup

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrl: './form.component.scss',
})
export class FormComponent implements OnInit {
  @Input({ required: true }) formControls!: FormGroup<any>;
  @Input({ required: true }) fieldsConfig!: any[];
  @Input() standard: boolean = true;
  @Input() onSubmitConfig!:
    | IOnSubmitConfig
    | IOnSubmitConfigEdit
    | IOnSubmitConfigAdd;
  editMode = false;
  constructor(
    private location: Location,
    private notificationService: NotificationService,
    private router: Router
  ) {}
  ngOnInit(): void {
    if (this.isIOnSubmitConfigWithEdit(this.onSubmitConfig)) {
      this.editMode = true;
    }
  }
  get controls(): { [key: string]: FormControl } {
    return this.formControls.controls as { [key: string]: FormControl };
  }

  onSubmitObserver = {
    next: () => {
      this.isIOnSubmitConfigWithEdit(this.onSubmitConfig)
        ? this.notificationService.showSuccess(
            this.onSubmitConfig.successEditText!
          )
        : this.notificationService.showSuccess(
            this.onSubmitConfig.successAddText
          );
    },
    error: (err: any) => {
      if (err instanceof CustomValidationError) {
        const errors = err.errorObject;
        Object.keys(errors).forEach((key) => {
          const control = this.formControls.get(key);
          if (control) {
            control.setErrors({ serverError: errors[key][0] });
          }
        });
        this.notificationService.showError(err.message);
      } else {
        this.notificationService.showError(err.message);
      }
    },
    complete: () => {
      if (
        this.onSubmitConfig.navigateTo.queryParams &&
        Object.keys(this.onSubmitConfig.navigateTo.queryParams).length > 0
      ) {
        this.router.navigate(this.onSubmitConfig.navigateTo.path, {
          queryParams: this.onSubmitConfig.navigateTo.queryParams,
        });
      } else {
        this.router.navigate(this.onSubmitConfig.navigateTo.path);
      }
    },
  };

  onSubmitClick() {
    this.isIOnSubmitConfigWithEdit(this.onSubmitConfig)
      ? this.onSubmitConfig.editCallback!(
          this.onSubmitConfig.elementId!,
          this.formControls.getRawValue()
        ).subscribe(this.onSubmitObserver)
      : this.onSubmitConfig
          .addCallback(this.formControls.getRawValue())
          .subscribe(this.onSubmitObserver);
  }
  goBack(): void {
    if (
      this.onSubmitConfig.navigateTo.queryParams &&
      Object.keys(this.onSubmitConfig.navigateTo.queryParams).length > 0
    ) {
      this.router.navigate(this.onSubmitConfig.navigateTo.path, {
        queryParams: this.onSubmitConfig.navigateTo.queryParams,
      });
    } else {
      this.location.back();
    }
  }

  private isIOnSubmitConfigWithEdit(
    config: IOnSubmitConfig | IOnSubmitConfigEdit | IOnSubmitConfigAdd
  ): config is IOnSubmitConfigEdit {
    return (config as IOnSubmitConfigEdit).elementId !== null;
  }
}
