import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PanelMenuModule } from 'primeng/panelmenu';
import { ToolbarModule } from 'primeng/toolbar';
import { InputTextModule } from 'primeng/inputtext';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { AvatarModule } from 'primeng/avatar';
import { SidebarModule } from 'primeng/sidebar';
import { InputSwitchModule } from 'primeng/inputswitch';
import { PasswordModule } from 'primeng/password';
import { DropdownModule } from 'primeng/dropdown';
import { TagModule } from 'primeng/tag';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { CheckboxModule } from 'primeng/checkbox';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressBarModule } from 'primeng/progressbar';
import { CalendarModule } from 'primeng/calendar';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TabViewModule } from 'primeng/tabview';
import { BadgeModule } from 'primeng/badge';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { PickListModule } from 'primeng/picklist';
import { DragDropModule } from 'primeng/dragdrop';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AccordionModule } from 'primeng/accordion';
import { ImageModule } from 'primeng/image';
import { TimelineModule } from 'primeng/timeline';
import { CardModule } from 'primeng/card';
import { InputNumberModule } from 'primeng/inputnumber';
import { DialogModule } from 'primeng/dialog';
import { OrderListModule } from 'primeng/orderlist';
import { DividerModule } from 'primeng/divider';
import { PanelModule } from 'primeng/panel';
import { FieldsetModule } from 'primeng/fieldset';
import { TreeTableModule } from 'primeng/treetable';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [
    OrderListModule,
    PanelMenuModule,
    ToolbarModule,
    InputTextModule,
    BreadcrumbModule,
    TableModule,
    ButtonModule,
    AvatarModule,
    SidebarModule,
    InputSwitchModule,
    PasswordModule,
    DropdownModule,
    TagModule,
    ConfirmPopupModule,
    CheckboxModule,
    TriStateCheckboxModule,
    MultiSelectModule,
    ProgressBarModule,
    CalendarModule,
    AutoCompleteModule,
    TabViewModule,
    BadgeModule,
    InputTextareaModule,
    DragDropModule,
    PickListModule,
    ProgressSpinnerModule,
    AccordionModule,
    ImageModule,
    TimelineModule,
    CardModule,
    InputNumberModule,
    DialogModule,
    DividerModule,
    PanelModule,
    FieldsetModule,
    TreeTableModule,
  ],
})
export class PrimeModule {}
