<div class="form-wrapper">
  <div class="card flex justify-content-center card--form">
    <form
      *ngIf="fieldsConfig"
      [formGroup]="formControls"
      (ngSubmit)="onSubmitClick()"
      class="add-edit-form"
      [class.big]="!standard"
    >
      <div class="inputs-wrapper">
        <div class="standard-inputs">
          <ng-container *ngFor="let field of fieldsConfig">
            <ng-container *ngIf="field.type !== 'pick'">
              <div class="form-input-container">
                <ng-container [ngSwitch]="field.type">
                  <ng-container *ngSwitchCase="'text'">
                    <app-text-input
                      [controlName]="controls[field.id]"
                      [inputId]="field.id"
                      [label]="field.label"
                    >
                    </app-text-input>
                  </ng-container>
                  <ng-container *ngSwitchCase="'number'">
                    <app-number-input
                      [controlName]="controls[field.id]"
                      [inputId]="field.id"
                      [label]="field.label"
                    >
                    </app-number-input>
                  </ng-container>
                  <ng-container *ngSwitchCase="'dropdown'">
                    <app-dropdown-input
                      [controlName]="controls[field.id]"
                      [inputId]="field.id"
                      [label]="field.label"
                      [options]="field.options"
                      [placeholder]="field.placeholder"
                    >
                    </app-dropdown-input>
                  </ng-container>
                  <ng-container
                    *ngSwitchCase="'custom-packaging-groups-elements-input'"
                  >
                    <app-custom-packaging-groups-elements-input
                      [controlName]="controls[field.id]"
                      [inputId]="field.id"
                      [label]="field.label"
                      [options]="field.options"
                      [placeholder]="field.placeholder"
                      [currentElements]="field.currentElements"
                      [packagingGroupId]="field.packagingGroupId"
                    >
                    </app-custom-packaging-groups-elements-input>
                  </ng-container>
                  <ng-container *ngSwitchCase="'custom-recipe-input'">
                    <app-custom-recipe-input
                      [controlName]="controls[field.id]"
                      [passedCurrentElements]="field.currentElements"
                    >
                    </app-custom-recipe-input>
                  </ng-container>
                  <ng-container *ngSwitchCase="'recipe-input'">
                    <app-recipe-input
                      [controlName]="controls[field.id]"
                      [passedCurrentElements]="field.currentElements"
                    >
                    </app-recipe-input>
                  </ng-container>
                  <ng-container *ngSwitchCase="'production-steps-input'">
                    <app-production-steps-input
                      [controlName]="controls[field.id]"
                      [productionStandsList]="field.standsList"
                      [productionLinesList]="field.linesList"
                      [passedCurrentProductionStepsElements]="
                        field.currentElements
                      "
                    >
                    </app-production-steps-input>
                  </ng-container>
                  <ng-container *ngSwitchCase="'custom-production-steps-input'">
                    <app-custom-production-steps-input
                      [controlName]="controls[field.id]"
                      [passedCurrentProductionStepsElements]="
                        field.currentElements
                      "
                    >
                    </app-custom-production-steps-input>
                  </ng-container>
                  <ng-container *ngSwitchCase="'date'">
                    <app-date-input
                      [controlName]="controls[field.id]"
                      [inputId]="field.id"
                      [label]="field.label"
                      [date]="field.date"
                      [placeholder]="field.placeholder"
                    >
                    </app-date-input>
                  </ng-container>
                  <ng-container *ngSwitchCase="'multiselect'">
                    <app-multi-select-input
                      [controlName]="controls[field.id]"
                      [inputId]="field.id"
                      [label]="field.label"
                      [options]="field.options"
                      [placeholder]="field.placeholder"
                    >
                    </app-multi-select-input>
                  </ng-container>
                  <ng-container *ngSwitchCase="'switch'">
                    <app-switch-input
                      [controlName]="controls[field.id]"
                      [inputId]="field.id"
                      [label]="field.label"
                    >
                    </app-switch-input>
                  </ng-container>
                  <ng-container *ngSwitchCase="'textarea'">
                    <app-textarea-input
                      [controlName]="controls[field.id]"
                      [inputId]="field.id"
                      [label]="field.label"
                    >
                    </app-textarea-input>
                  </ng-container>
                </ng-container>
                <small
                  class="error"
                  *ngIf="controls[field.id]?.errors?.['serverError']"
                  >{{
    controls[field.id].errors?.['serverError']
                  }}</small
                >
              </div>
            </ng-container>
          </ng-container>
        </div>

        <div class="non-standard-inputs">
          <ng-container *ngFor="let field of fieldsConfig">
            <div class="form-input-container">
              <ng-container *ngIf="field.type === 'pick'">
                <app-pick-list-input
                  [options]="field.options"
                  [controlName]="controls[field.id]"
                  [inputId]="field.id"
                  [label]="field.label"
                  [currentSelection]="field.currentSelection"
                >
                </app-pick-list-input>
                <small
                  class="error"
                  *ngIf="controls[field.id]?.errors?.['serverError']"
                  >{{
        controls[field.id].errors?.['serverError']
                  }}</small
                >
              </ng-container>
              <ng-container *ngIf="field.type === 'multi-switch'">
                <app-multi-switch-input
                  [options]="field.options"
                  [controlName]="controls[field.id]"
                  [inputId]="field.id"
                  [label]="field.label"
                  [currentSelection]="field.currentSelection"
                >
                </app-multi-switch-input>
                <small
                  class="error"
                  *ngIf="controls[field.id]?.errors?.['serverError']"
                  >{{
        controls[field.id].errors?.['serverError']
                  }}</small
                >
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="flex justify-content-end gap-3 mt-6">
        <p-button
          class="form-btn"
          severity="danger"
          label="Anuluj"
          type="button"
          (onClick)="goBack()"
        ></p-button>

        <p-button
          [label]="editMode ? 'Zapisz' : 'Dodaj'"
          [disabled]="formControls.invalid"
          type="submit"
          class="form-btn"
        ></p-button>
      </div>
    </form>
  </div>
</div>

<!-- //TODO: sprawdzić wyświetlanie errorów -->
