import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-decimal-color-table-field',
  templateUrl: './decimal-color-table-field.component.html',
  styleUrl: './decimal-color-table-field.component.scss',
})
export class DecimalColorTableFieldComponent implements OnInit {
  class: string = '';
  @Input({ required: true }) value!: number;
  ngOnInit(): void {
    this.value == 0
      ? (this.class = '')
      : this.value > 0
      ? (this.class = 'red')
      : (this.class = 'yellow');
  }
}
