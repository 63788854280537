import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-filter-element',
  templateUrl: './filter-element.component.html',
  styleUrl: './filter-element.component.scss',
})
export class FilterElementComponent {
  @Input() isFilterSidebarOpen = false;
  @Input({ required: true }) filtersConfig: any;
  @Output() clearFilters = new EventEmitter<void>();
  @Output() changeSorting = new EventEmitter<{
    value: any;
    fieldName: any;
    matchMode: any;
  }>();
  @Output() closeFilterSidebar = new EventEmitter<void>();
  // @Output() changeInputSorting = new EventEmitter<string>();
  // @Output() onInit = new EventEmitter<[]>();
  clearChildsFilters = false;
  onChange(value: any, fieldName: any, matchMode: any) {
    this.clearChildsFilters = false;
    this.changeSorting.emit({ value, fieldName, matchMode });
  }
  handleClearFilters() {
    this.clearChildsFilters = true;
    this.clearFilters.emit();
  }
  onCloseSidebar() {
    this.closeFilterSidebar.emit();
  }
}
