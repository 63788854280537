<div>
  <label class="font-semibold w-6rem">Kroki Produkcyjne</label>
</div>
<div class="custom-input-wrapper">
  <p-dialog
    header="Dodaj krok produkcyjny"
    [modal]="true"
    [(visible)]="addDialogVisible"
    [style]="{ width: '25rem', 'min-height': '50rem' }"
    (onHide)="closeAddDialog()"
  >
    <div class="form-input-container">
      <div class="form-input flex flex-column gap-2">
        <label for="material" class="font-semibold w-6rem">Nazwa</label>
        <input type="text" pInputText [(ngModel)]="dialogName" />
      </div>
    </div>
    <div class="form-input-container">
      <div class="form-input flex flex-column gap-2">
        <div class="label-flex-container">
          <p-checkbox [(ngModel)]="checkAging" [binary]="true" />
          <label for="material" class="font-semibold w-6rem">Leżakowanie</label>
        </div>
        <p-inputNumber
          [(ngModel)]="dialogAgingValue"
          mode="decimal"
          inputId="withoutgrouping"
          [useGrouping]="false"
          [disabled]="!checkAging"
          placeholder="Czas leżakowania w godzinach"
        />
      </div>
    </div>
    <div class="form-input-container">
      <div class="form-input flex flex-column gap-2">
        <label for="qty" class="font-semibold w-6rem">{{
          optionsContext === "production-line" ? "Linia" : "Stanowisko"
        }}</label>

        <p-dropdown
          id="material"
          [options]="
            optionsContext === 'production-line'
              ? productionLinesList
              : productionStandsList
          "
          [filter]="true"
          filterBy="name"
          optionLabel="name"
          [showClear]="true"
          [placeholder]="
            optionsContext === 'production-line'
              ? 'Wybierz linie'
              : 'Wybierz stanowisko'
          "
          [(ngModel)]="dialogSelectedOption"
        ></p-dropdown>
      </div>
    </div>
    <div class="form-input-container">
      <div class="form-input flex flex-column gap-2">
        <label for="qty" class="font-semibold w-6rem">Notatka</label>
        <textarea
          rows="5"
          cols="30"
          pInputTextarea
          [(ngModel)]="dialogTextAreaValue"
        ></textarea>
      </div>
    </div>
    <div class="flex justify-content-end gap-2">
      <p-button
        label="Anuluj"
        severity="danger"
        (onClick)="addDialogVisible = false"
      />
      <p-button
        label="Dodaj"
        severity="success"
        (onClick)="addToProductionSteps()"
      />
    </div>
  </p-dialog>
  <div class="elements-list-wrapper">
    <p-orderList
      [value]="currentProductionStepsElements"
      [listStyle]="{ 'max-height': '30rem' }"
      [dragdrop]="true"
      (onReorder)="setControlValue()"
    >
      <ng-template let-element pTemplate="item">
        <div class="element-wrapper">
          <div class="info-area">
            <div class="element-text-container">
              {{ element.name }}
            </div>
            <div class="element-text-container">
              {{ element.prod_info }}
            </div>
            <div class="element-note-container">
              {{ element.note }}
            </div>
          </div>

          <div class="element-btn-container">
            <p-button
              type="button"
              icon="pi pi-file-edit"
              styleClass="p-button-text p-button-custom"
              severity="info"
              (onClick)="showEditDialog(element)"
            ></p-button>
            <p-button
              (onClick)="deleteMaterial(element)"
              type="button"
              icon="pi pi-trash"
              styleClass="p-button-text p-button-custom"
              severity="danger"
            ></p-button>
          </div>
        </div>
      </ng-template>
    </p-orderList>
    <div class="custom-input-btn-wrapper">
      <p-button
        pTooltip="Dodaj linie produkcyjną"
        icon="pi pi-forward"
        (onClick)="showAddDialog('production-line')"
      />
      <p-button
        pTooltip="Dodaj stanowisko produkcyjne"
        icon="pi pi-wrench"
        (onClick)="showAddDialog('production-stand')"
      />
    </div>
  </div>

  <p-dialog
    header="Edycja kroku produkcyjnego"
    [modal]="true"
    [(visible)]="editDialogVisible"
    [style]="{ width: '25rem', 'min-height': '50rem' }"
    (onHide)="closeAddDialog()"
    *ngIf="currentSelectedToEdit"
  >
    <div class="form-input flex flex-column gap-2">
      <label for="material" class="font-semibold w-6rem">{{
        optionsContext === "production-line" ? "Linia" : "Stanowisko"
      }}</label>
      {{ currentSelectedToEdit.prod_info }}
    </div>
    <div class="form-input flex flex-column gap-2">
      <label for="material" class="font-semibold w-6rem">Nazwa</label>
      <input type="text" pInputText [(ngModel)]="editDialogName" />
    </div>
    <div class="form-input flex flex-column gap-2">
      <div class="label-flex-container">
        <label for="material" class="font-semibold w-6rem">Leżakowanie</label>
        <p-checkbox [(ngModel)]="editCheckAging" [binary]="true" />
      </div>
      <p-inputNumber
        [(ngModel)]="editDialogAgingValue"
        mode="decimal"
        inputId="withoutgrouping"
        [useGrouping]="false"
        [disabled]="!editCheckAging"
        placeholder="Czas leżakowania w godzinach"
      />
    </div>
    <div class="form-input flex flex-column gap-2">
      <label for="qty" class="font-semibold w-6rem">Notatka</label>
      <textarea
        rows="5"
        cols="30"
        pInputTextarea
        [(ngModel)]="editDialogTextAreaValue"
      ></textarea>
    </div>
    <div class="flex justify-content-end gap-2">
      <p-button
        label="Anuluj"
        severity="danger"
        (onClick)="editDialogVisible = false"
      />
      <p-button
        label="Zapisz"
        severity="success"
        (onClick)="editProductionStep()"
      />
    </div>
  </p-dialog>
</div>
