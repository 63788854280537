import { Component, Input, OnInit } from '@angular/core';
import { IConfigNotification } from '../../../../core/models/configNotification';

@Component({
  selector: 'app-text-with-notification-table-field',
  templateUrl: './text-with-notification-table-field.component.html',
  styleUrl: './text-with-notification-table-field.component.scss',
})
export class TextWithNotificationTableFieldComponent implements OnInit {
  @Input({ required: true }) element!: any;
  @Input({ required: true }) fields: IConfigNotification[] | null = null;
  @Input() mainField!: string;
  value!: string;

  notification: string = '';

  ngOnInit(): void {
    this.value = this.element[this.mainField] ?? '---';
    let newNotification = '';
    this.fields?.forEach((field) => {
      if (this.element[field.field]) {
        newNotification += ` ${field.text}`;
      }
    });
    this.notification = newNotification;
  }
}
