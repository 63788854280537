import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IColumnConfig } from '../../../core/models/columnsConfig';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-table-simplified',
  templateUrl: './table-simplified.component.html',
  styleUrl: './table-simplified.component.scss',
})
export class TableSimplifiedComponent implements OnInit {
  ngOnInit(): void {
    console.log(this.data);
  }
  @Input({ required: true }) data!: any[];
  @Input({ required: true }) columnsConfig!: IColumnConfig[];
}
