import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PrimeModule } from './prime/prime.module';
import { TableComponent } from './components/table/table.component';
import { FilterElementComponent } from './components/filter-element/filter-element.component';
import { TextTableFieldComponent } from './components/fields/text-table-field/text-table-field.component';
import { BooleanTableFieldComponent } from './components/fields/boolean-table-field/boolean-table-field.component';
import { DecimalTableFieldComponent } from './components/fields/decimal-table-field/decimal-table-field.component';
import { LabelTableFieldComponent } from './components/fields/label-table-field/label-table-field.component';
import { IconTableFieldComponent } from './components/fields/icon-table-field/icon-table-field.component';
import { UrlTableFieldComponent } from './components/fields/url-table-field/url-table-field.component';
import { DropdownFilterComponent } from './components/filters/dropdown-filter/dropdown-filter.component';
import { TriStateFilterComponent } from './components/filters/tri-state-filter/tri-state-filter.component';
import { ActionsTableFieldComponent } from './components/fields/actions-table-field/actions-table-field.component';
import { InputFilterComponent } from './components/filters/input-filter/input-filter.component';
import { ConfirmationService } from 'primeng/api';
import { DropdownMultiFilterComponent } from './components/filters/dropdown-multi-filter/dropdown-multi-filter.component';
import { ProgressComponent } from './components/fields/progress/progress.component';
import { DateFilterComponent } from './components/filters/date-filter/date-filter.component';
import { AutocompleteFilterComponent } from './components/filters/autocomplete-filter/autocomplete-filter.component';
import { TableSimplifiedComponent } from './components/table-simplified/table-simplified.component';
import { DecimalColorTableFieldComponent } from './components/fields/decimal-color-table-field/decimal-color-table-field.component';
import { FormComponent } from './components/form/form.component';
import { TextInputComponent } from './components/form-inputs/text-input/text-input.component';
import { DropdownInputComponent } from './components/form-inputs/dropdown-input/dropdown-input.component';
import { SwitchInputComponent } from './components/form-inputs/switch-input/switch-input.component';
import { TextareaInputComponent } from './components/form-inputs/textarea-input/textarea-input.component';
import { PickListInputComponent } from './components/form-inputs/pick-list-input/pick-list-input.component';
import { TableSimplifiedFiltersComponent } from './components/table-simplified-filters/table-simplified-filters.component';
import { IconStatusFieldComponent } from './components/fields/icon-status-field/icon-status-field.component';
import { DropdownStringValueFilterComponent } from './components/filters/dropdown-string-value-filter/dropdown-string-value-filter.component';
import { CustomDecimalComparisonColorComponent } from './components/fields/custom-decimal-comparison-color/custom-decimal-comparison-color.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { NumberInputComponent } from './components/form-inputs/number-input/number-input.component';
import { MultiSwitchInputComponent } from './components/form-inputs/multi-switch-input/multi-switch-input.component';
import { TextWithNotificationTableFieldComponent } from './components/fields/text-with-notification-table-field/text-with-notification-table-field.component';
import { MultiSelectInputComponent } from './components/form-inputs/multi-select-input/multi-select-input.component';
import { DateInputComponent } from './components/form-inputs/date-input/date-input.component';
import { DialogListComponent } from './components/dialog-list/dialog-list.component';
import { CustomPackagingGroupsElementsInputComponent } from './components/form-inputs/custom-packaging-groups-elements-input/custom-packaging-groups-elements-input.component';
import { ExpansionTableComponent } from './components/expansion-table/expansion-table.component';
import { CustomRecipeInputComponent } from './components/form-inputs/custom-recipe-input/custom-recipe-input.component';
import { CustomProductionStepsInputComponent } from './components/form-inputs/custom-production-steps-input/custom-production-steps-input.component';
import { RecipeInputComponent } from './components/form-inputs/recipe-input/recipe-input.component';
import { ProductionStepsInputComponent } from './components/form-inputs/production-steps-input/production-steps-input.component';

@NgModule({
  providers: [ConfirmationService],
  declarations: [
    TableComponent,
    FilterElementComponent,
    TextTableFieldComponent,
    BooleanTableFieldComponent,
    DecimalTableFieldComponent,
    LabelTableFieldComponent,
    IconTableFieldComponent,
    UrlTableFieldComponent,
    FilterElementComponent,
    DropdownFilterComponent,
    TriStateFilterComponent,
    ActionsTableFieldComponent,
    InputFilterComponent,
    DropdownMultiFilterComponent,
    ProgressComponent,
    DateFilterComponent,
    AutocompleteFilterComponent,
    TableSimplifiedComponent,
    DecimalColorTableFieldComponent,
    FormComponent,
    TextInputComponent,
    DropdownInputComponent,
    SwitchInputComponent,
    TextareaInputComponent,
    PickListInputComponent,
    TableSimplifiedFiltersComponent,
    IconStatusFieldComponent,
    DropdownStringValueFilterComponent,
    CustomDecimalComparisonColorComponent,
    GalleryComponent,
    TimelineComponent,
    NumberInputComponent,
    MultiSwitchInputComponent,
    TextWithNotificationTableFieldComponent,
    MultiSelectInputComponent,
    DateInputComponent,
    DialogListComponent,
    CustomPackagingGroupsElementsInputComponent,
    ExpansionTableComponent,
    CustomRecipeInputComponent,
    CustomProductionStepsInputComponent,
    RecipeInputComponent,
    ProductionStepsInputComponent,
  ],
  imports: [PrimeModule, CommonModule, FormsModule, ReactiveFormsModule],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PrimeModule,
    TableComponent,
    FilterElementComponent,
    TableSimplifiedComponent,
    FormComponent,
    TableSimplifiedFiltersComponent,
    GalleryComponent,
    TimelineComponent,
    DialogListComponent,
    ExpansionTableComponent,
  ],
})
export class SharedModule {}
