import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  styleUrl: './date-input.component.scss',
})
export class DateInputComponent implements OnInit {
  ngOnInit(): void {
    if (this.controlName.value && this.controlName.value !== '') {
      this.date = new Date(this.controlName.value);
    }
  }
  @Input({ required: true }) controlName!: FormControl<any>;
  @Input({ required: true }) date!: Date;
  @Input({ required: true }) inputId!: string;
  @Input({ required: true }) label!: string;
  @Input() placeholder: string = 'Wybierz opcję';

  onChange() {
    const formattedDateElement = this.formatDate(this.date);

    this.controlName.setValue(formattedDateElement);
  }

  onClear() {
    this.controlName.setValue(null);
  }

  // TODO - zrobić z tego helper i zmienić tez w filtrach
  private formatDate(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  parseDateString(dateStr: string): Date {
    const parts = dateStr.split('.');
    if (parts.length === 3) {
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[2], 10);
      return new Date(year, month, day);
    } else {
      throw new Error('Invalid date format');
    }
  }
}
