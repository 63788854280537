import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import {
  IPredefinedDataResponseDocumentsTypes,
  IPredefinedDataResponseWarehousesLocations,
} from '../models/predefinedDataResponses';
import { Observable } from 'rxjs';
import { PREDEFINED_API_ENDPOINTS } from '../models/predefinedDataEndpoints';
import { IPredefinedDataDocumentsTypes } from '../models/predefinedDataDocumentsTypes';
import { IPredefinedDataResponseProductionUnitsTypes } from '../models/predefinedDataResponseProductionUnitsTypes';
import { ILocationsResponse } from '../../warehouse/models/locationsResponse.model';
import { LOCATION_API_ENDPOINTS } from '../models/locations-api-endpoints';
import { IProductionStandsListResponse } from '../../production/models/productionStandsListResponse';
import { PRODUCTION_Stands_API_ENDPOINTS } from '../models/productionStandsApiEndpoints';
import { IClientGroupListResponse } from '../models/clientsGroupListResponse';
import { IClientTypesListResponse } from '../models/clientTypesListResponse';
import { IClientCountryListResponse } from '../models/clientCountryListResponse';
import { IClientProvinceListResponse } from '../models/clientProvinceListResponse';
import { WarehouseResponse } from '../../warehouse/models/warehousesResponse.model';
import { WAREHOUSES_API_ENDPOINTS } from '../models/warehouses-api-endpoints';
import { IClientsCitiesListResponse } from '../models/clientCitiesListResponse';
import { IResourceCategoriesListResponse } from '../models/resourceCategoriesListResponse';
import { IBatchNumbersListResponse } from '../../warehouse/models/batchNumbersListResponse';
import { BATCH_NUMBERS_API_ENDPOINTS } from '../../warehouse/models/batchNumbersApiEndpoints';
import { IUsersRolesListResponse } from '../../administration/models/usersRolesListResponse';
import { USERS_API_ENDPOINTS } from '../../administration/models/usersApiEndpoints';
import { IUserRoleListElement } from '../../administration/models/usersRolesListElement';
import { IOrderStatusesListResponse } from '../../administration/models/orderStatusesListResponse';
import { IPackagingTypesListElement } from '../../administration/models/packagingTypesListElement';
import { DICTIONARY_API_ENDPOINTS } from '../../administration/models/dictionaryApiEndpoints';
import { IUsersParams } from '../../administration/models/usersParams';
import { IUsersListResponse } from '../../administration/models/usersListResponse';
import { IPackagingListElement } from '../../administration/models/packagingListElement';
import { IPackagingListResponse } from '../../administration/models/packagingListResponse';
import { IResourcesParams } from '../../resources/models/resourcesParams';
import { IResourcesListResponse } from '../../resources/models/resourcesListResponse';
import { RESOURCES_API_ENDPOINTS } from '../../resources/models/resourcesApiEndpoints';
import { PRODUCTION_LINES_API_ENDPOINTS } from '../models/productionLinesApiEndpoints';
import { IProductionLinesListResponse } from '../../production/models/productionLinesListResponse';
import { CLIENTS_API_ENDPOINTS } from '../../clients/models/clientsApiEndpoints';
import { IClientsListResponse } from '../../clients/models/clientsListResponse';
import { IOrdersListResponse } from '../../orders/models/ordersListResponse';
import { ORDERS_API_ENDPOINTS } from '../../orders/models/ordersApiEndpoints';
import {IProductionStatusesListResponse} from "../../administration/models/productionStatusesListResponse";

@Injectable({
  providedIn: 'root',
})
export class PredefinedDataService {
  constructor(private apiService: ApiService) {}

  getWarehousesLocations(): Observable<IPredefinedDataResponseWarehousesLocations> {
    return this.apiService.get<IPredefinedDataResponseWarehousesLocations>(
      PREDEFINED_API_ENDPOINTS.PredefinedDataWarehousesLocations
    );
  }

  getWarehousesList(): Observable<WarehouseResponse> {
    return this.apiService.get<WarehouseResponse>(
      WAREHOUSES_API_ENDPOINTS.WarehousesList
    );
  }

  getUsersList(): Observable<IUsersListResponse> {
    return this.apiService.get<IUsersListResponse>(USERS_API_ENDPOINTS.List);
  }
  getDocumentsTypes(): Observable<IPredefinedDataResponseDocumentsTypes> {
    return this.apiService.get<IPredefinedDataResponseDocumentsTypes>(
      PREDEFINED_API_ENDPOINTS.PredefinedDataDocumentsTypes
    );
  }

  getProductionUnitsTypes(): Observable<IPredefinedDataResponseProductionUnitsTypes> {
    return this.apiService.get<IPredefinedDataResponseProductionUnitsTypes>(
      PREDEFINED_API_ENDPOINTS.PredefinedDataProductionUnitsType
    );
  }

  getProductionStatuses(): Observable<IProductionStatusesListResponse> {
    return this.apiService.get<IProductionStatusesListResponse>(
      PREDEFINED_API_ENDPOINTS.PredefinedProductionStatuses
    );
  }

  getLocationsList(magazine_id?: string): Observable<ILocationsResponse> {
    return this.apiService.get<ILocationsResponse>(
      LOCATION_API_ENDPOINTS.LocationsList,
      {
        magazine_id,
      }
    );
  }

  getProductionStandsList(): Observable<IProductionStandsListResponse> {
    return this.apiService.get<IProductionStandsListResponse>(
      PRODUCTION_Stands_API_ENDPOINTS.List
    );
  }
  getProductionLinesList(): Observable<IProductionLinesListResponse> {
    return this.apiService.get<IProductionLinesListResponse>(
      PRODUCTION_LINES_API_ENDPOINTS.List
    );
  }
  getClientsList(): Observable<IClientsListResponse> {
    return this.apiService.get<IClientsListResponse>(
      CLIENTS_API_ENDPOINTS.List
    );
  }
  getClientGroups(): Observable<IClientGroupListResponse> {
    return this.apiService.get<IClientGroupListResponse>(
      PREDEFINED_API_ENDPOINTS.PredefinedClientGroups
    );
  }

  getClientTypes(): Observable<IClientTypesListResponse> {
    return this.apiService.get<IClientTypesListResponse>(
      PREDEFINED_API_ENDPOINTS.PredefinedClientTypes
    );
  }

  getClientCountries(): Observable<IClientCountryListResponse> {
    return this.apiService.get<IClientCountryListResponse>(
      PREDEFINED_API_ENDPOINTS.PredefinedClientCountries
    );
  }

  getClientProvinces(): Observable<IClientProvinceListResponse> {
    return this.apiService.get<IClientProvinceListResponse>(
      PREDEFINED_API_ENDPOINTS.PredefinedClientProvinces
    );
  }

  getClientCities(): Observable<IClientsCitiesListResponse> {
    return this.apiService.get<IClientsCitiesListResponse>(
      PREDEFINED_API_ENDPOINTS.PredefinedClientCities
    );
  }

  getOrderStatuses(): Observable<IOrderStatusesListResponse> {
    return this.apiService.get<IOrderStatusesListResponse>(
      PREDEFINED_API_ENDPOINTS.PredefinedOrderStatuses
    );
  }

  getOutgoOrdersList(): Observable<IOrdersListResponse> {
    return this.apiService.get<IOrdersListResponse>(ORDERS_API_ENDPOINTS.List, {
      direction: -1,
    });
  }

  getResourcesList(query?: string): Observable<IResourcesListResponse> {
    return this.apiService.get<IResourcesListResponse>(
      RESOURCES_API_ENDPOINTS.List,
      {
        query,
      }
    );
  }
  getResourceCategories(): Observable<IResourceCategoriesListResponse> {
    return this.apiService.get<IResourceCategoriesListResponse>(
      PREDEFINED_API_ENDPOINTS.PredefinedResourceCategories
    );
  }
  getResourceGroups(): Observable<IResourceCategoriesListResponse> {
    return this.apiService.get<IResourceCategoriesListResponse>(
      PREDEFINED_API_ENDPOINTS.PredefinedResourceGroups
    );
  }

  getBatchNumbers(): Observable<IBatchNumbersListResponse> {
    return this.apiService.get<IBatchNumbersListResponse>(
      BATCH_NUMBERS_API_ENDPOINTS.List
    );
  }

  getUsersRoles(): Observable<IUserRoleListElement[]> {
    return this.apiService.get<IUserRoleListElement[]>(
      USERS_API_ENDPOINTS.Roles
    );
  }

  getPackagingTypes(): Observable<IPackagingTypesListElement[]> {
    return this.apiService.get<IPackagingTypesListElement[]>(
      DICTIONARY_API_ENDPOINTS.Packaging_Types_List
    );
  }
  getPackagings(): Observable<IPackagingListResponse> {
    return this.apiService.get<IPackagingListResponse>(
      DICTIONARY_API_ENDPOINTS.Packaging_List
    );
  }
}
