import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  private handleError(error: any): Observable<never> {
    console.error('An error occurred:', error.error);
    return throwError(
      () => new Error('Something bad happened; please try again later.')
    );
  }

  private getParams(params?: { [param: string]: any | any[] }): HttpParams {
    let httpParams = new HttpParams();

    if (params) {
      Object.keys(params).forEach((key) => {
        const value = params[key];
        if (value == null) return;
        if (Array.isArray(value)) {
          value
            .filter((item) => item != null)
            .forEach((item, i) => {
              httpParams = httpParams.append(
                `${key}[${i}]`,
                this.serialize(item)
              );
            });
        } else {
          httpParams = httpParams.append(key, this.serialize(value));
        }
      });
    }
    return httpParams;
  }
  get<T>(url: string, params?: object): Observable<T> {
    return this.http
      .get<T>(this.apiUrl + url, { params: this.getParams(params) })
      .pipe(catchError(this.handleError));
  }

  post<TRequest, TResponse>(
    url: string,
    data: TRequest,
    params?: object
  ): Observable<TResponse> {
    return this.http.post<TResponse>(this.apiUrl + url, data, {
      params: this.getParams(params),
    });
  }

  patch<TRequest, TResponse>(
    url: string,
    data: TRequest,
    params?: object
  ): Observable<TResponse> {
    return this.http.patch<TResponse>(this.apiUrl + url, data, {
      params: this.getParams(params),
    });
  }

  put<TRequest, TResponse>(
    url: string,
    data: TRequest,
    params?: object
  ): Observable<TResponse> {
    return this.http
      .put<TResponse>(this.apiUrl + url, data, {
        params: this.getParams(params),
      })
      .pipe(catchError(this.handleError));
  }

  delete<T>(url: string, params?: object): Observable<T> {
    return this.http
      .delete<T>(this.apiUrl + url, { params: this.getParams(params) })
      .pipe(catchError(this.handleError));
  }

  download(
    url: string,
    params?: { [param: string]: any | any[] }
  ): Observable<Blob> {
    return this.http
      .get(this.apiUrl + url, {
        params: this.getParams(params),
        responseType: 'blob',
      })
      .pipe(catchError(this.handleError));
  }

  getCSRFCookie(): Observable<any> {
    return this.http
      .get('https://proplast.dev8.pdait.pl/sanctum/csrf-cookie')
      .pipe(catchError(this.handleError));
  }
  private serialize(value: any): string {
    if (typeof value === 'boolean') {
      return String(value);
    } else if (typeof value === 'number') {
      return value.toString();
    } else {
      return value;
    }
  }
}
