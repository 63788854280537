export enum PREDEFINED_API_ENDPOINTS {
  PredefinedDataWarehousesLocations = 'locations/get-data',
  PredefinedDataDocumentsTypes = 'material-types/list',
  PredefinedDataProductionUnitsType = 'carriers/get-data',
  PredefinedClientGroups = 'client-groups/list',
  PredefinedClientTypes = 'client-types/list',
  PredefinedClientCountries = 'clients/country-list',
  PredefinedClientProvinces = 'clients/province-list',
  PredefinedOrderStatuses = 'order-statuses/list',
  PredefinedClientCities = 'clients/city-list',
  PredefinedResourceCategories = 'item-categories/list',
  PredefinedResourceGroups = 'item-groups/list',
  PredefinedProductionStatuses = 'production-statuses/list'
}
