import {
  AfterContentInit,
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { DropdownChangeEvent } from 'primeng/dropdown';

@Component({
  selector: 'app-dropdown-multi-filter',
  templateUrl: './dropdown-multi-filter.component.html',
  styleUrl: './dropdown-multi-filter.component.scss',
})
export class DropdownMultiFilterComponent {
  @Input() selectedOptions: any | any[] | null = null;
  @Input({ required: true }) options!: any[];
  @Input({ required: true }) placeholder!: string;
  @Input() set clear(value: boolean) {
    if (value === true) {
      this.onClear();
    }
    this._clear = value;
  }
  private _clear = false;

  @Output() changeSorting = new EventEmitter<string | string[]>();

  onChange(event: DropdownChangeEvent) {
    this.changeSorting.emit(event.value);
  }
  onClear() {
    this.selectedOptions = null;
  }
}
