import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TriStateCheckboxChangeEvent } from 'primeng/tristatecheckbox';

@Component({
  selector: 'app-tri-state-filter',
  templateUrl: './tri-state-filter.component.html',
  styleUrl: './tri-state-filter.component.scss',
})
export class TriStateFilterComponent {
  private _clear = false;
  @Input() selectedOption: boolean | null = null;

  @Input() name!: string;
  @Input() set clear(value: boolean) {
    if (value === true) {
      this.onClear();
    }
    this._clear = value;
  }
  @Output() changeSorting = new EventEmitter<boolean | null>();

  onChange(event: TriStateCheckboxChangeEvent) {
    this.changeSorting.emit(event.value);
  }

  onClear() {
    this.selectedOption = null;
  }
}
