import { trigger, transition, style, animate } from '@angular/animations';
import * as i2 from '@angular/common';
import { DOCUMENT, CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { EventEmitter, Component, ChangeDetectionStrategy, ViewEncapsulation, Inject, Input, Output, ViewChild, ContentChildren, HostListener, NgModule } from '@angular/core';
import * as i1 from 'primeng/api';
import { PrimeTemplate, SharedModule } from 'primeng/api';
import { DomHandler } from 'primeng/dom';
import { EyeIcon } from 'primeng/icons/eye';
import { RefreshIcon } from 'primeng/icons/refresh';
import { SearchMinusIcon } from 'primeng/icons/searchminus';
import { SearchPlusIcon } from 'primeng/icons/searchplus';
import { TimesIcon } from 'primeng/icons/times';
import { UndoIcon } from 'primeng/icons/undo';
import { ZIndexUtils } from 'primeng/utils';
import * as i3 from 'primeng/focustrap';
import { FocusTrapModule } from 'primeng/focustrap';

/**
 * Displays an image with preview and tranformation options. For multiple image, see Galleria.
 * @group Components
 */
const _c0 = ["mask"];
const _c1 = ["previewButton"];
const _c2 = ["closeButton"];
const _c3 = (a0, a1) => ({
  height: a0,
  width: a1
});
const _c4 = (a0, a1) => ({
  showTransitionParams: a0,
  hideTransitionParams: a1
});
const _c5 = a0 => ({
  value: "visible",
  params: a0
});
function Image_button_2_ng_container_2_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function Image_button_2_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, Image_button_2_ng_container_2_ng_container_1_Template, 1, 0, "ng-container", 10);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.indicatorTemplate);
  }
}
function Image_button_2_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "EyeIcon", 11);
  }
  if (rf & 2) {
    i0.ɵɵproperty("styleClass", "p-image-preview-icon");
  }
}
function Image_button_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 8, 0);
    i0.ɵɵlistener("click", function Image_button_2_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onImageClick());
    });
    i0.ɵɵtemplate(2, Image_button_2_ng_container_2_Template, 2, 1, "ng-container", 9)(3, Image_button_2_ng_template_3_Template, 1, 1, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const defaultTemplate_r3 = i0.ɵɵreference(4);
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngStyle", i0.ɵɵpureFunction2(4, _c3, ctx_r1.height + "px", ctx_r1.width + "px"));
    i0.ɵɵattribute("aria-label", ctx_r1.zoomImageAriaLabel);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r1.indicatorTemplate)("ngIfElse", defaultTemplate_r3);
  }
}
function Image_div_3_RefreshIcon_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "RefreshIcon");
  }
}
function Image_div_3_5_ng_template_0_Template(rf, ctx) {}
function Image_div_3_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Image_div_3_5_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function Image_div_3_UndoIcon_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "UndoIcon");
  }
}
function Image_div_3_8_ng_template_0_Template(rf, ctx) {}
function Image_div_3_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Image_div_3_8_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function Image_div_3_SearchMinusIcon_10_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "SearchMinusIcon");
  }
}
function Image_div_3_11_ng_template_0_Template(rf, ctx) {}
function Image_div_3_11_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Image_div_3_11_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function Image_div_3_SearchPlusIcon_13_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "SearchPlusIcon");
  }
}
function Image_div_3_14_ng_template_0_Template(rf, ctx) {}
function Image_div_3_14_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Image_div_3_14_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function Image_div_3_TimesIcon_17_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "TimesIcon");
  }
}
function Image_div_3_18_ng_template_0_Template(rf, ctx) {}
function Image_div_3_18_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Image_div_3_18_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function Image_div_3_div_19_Template(rf, ctx) {
  if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵlistener("@animation.start", function Image_div_3_div_19_Template_div_animation_animation_start_0_listener($event) {
      i0.ɵɵrestoreView(_r5);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.onAnimationStart($event));
    })("@animation.done", function Image_div_3_div_19_Template_div_animation_animation_done_0_listener($event) {
      i0.ɵɵrestoreView(_r5);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.onAnimationEnd($event));
    });
    i0.ɵɵelementStart(1, "img", 17);
    i0.ɵɵlistener("click", function Image_div_3_div_19_Template_img_click_1_listener() {
      i0.ɵɵrestoreView(_r5);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.onPreviewImageClick());
    });
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("@animation", i0.ɵɵpureFunction1(8, _c5, i0.ɵɵpureFunction2(5, _c4, ctx_r1.showTransitionOptions, ctx_r1.hideTransitionOptions)));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngStyle", ctx_r1.imagePreviewStyle());
    i0.ɵɵattribute("src", ctx_r1.previewImageSrc ? ctx_r1.previewImageSrc : ctx_r1.src, i0.ɵɵsanitizeUrl)("srcset", ctx_r1.previewImageSrcSet)("sizes", ctx_r1.previewImageSizes);
  }
}
function Image_div_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 12, 2);
    i0.ɵɵlistener("click", function Image_div_3_Template_div_click_0_listener() {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onMaskClick());
    })("keydown", function Image_div_3_Template_div_keydown_0_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onMaskKeydown($event));
    });
    i0.ɵɵelementStart(2, "div", 13);
    i0.ɵɵlistener("click", function Image_div_3_Template_div_click_2_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.handleToolbarClick($event));
    });
    i0.ɵɵelementStart(3, "button", 14);
    i0.ɵɵlistener("click", function Image_div_3_Template_button_click_3_listener() {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.rotateRight());
    });
    i0.ɵɵtemplate(4, Image_div_3_RefreshIcon_4_Template, 1, 0, "RefreshIcon", 15)(5, Image_div_3_5_Template, 1, 0, null, 10);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(6, "button", 14);
    i0.ɵɵlistener("click", function Image_div_3_Template_button_click_6_listener() {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.rotateLeft());
    });
    i0.ɵɵtemplate(7, Image_div_3_UndoIcon_7_Template, 1, 0, "UndoIcon", 15)(8, Image_div_3_8_Template, 1, 0, null, 10);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(9, "button", 16);
    i0.ɵɵlistener("click", function Image_div_3_Template_button_click_9_listener() {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.zoomOut());
    });
    i0.ɵɵtemplate(10, Image_div_3_SearchMinusIcon_10_Template, 1, 0, "SearchMinusIcon", 15)(11, Image_div_3_11_Template, 1, 0, null, 10);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(12, "button", 16);
    i0.ɵɵlistener("click", function Image_div_3_Template_button_click_12_listener() {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.zoomIn());
    });
    i0.ɵɵtemplate(13, Image_div_3_SearchPlusIcon_13_Template, 1, 0, "SearchPlusIcon", 15)(14, Image_div_3_14_Template, 1, 0, null, 10);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(15, "button", 14, 3);
    i0.ɵɵlistener("click", function Image_div_3_Template_button_click_15_listener() {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.closePreview());
    });
    i0.ɵɵtemplate(17, Image_div_3_TimesIcon_17_Template, 1, 0, "TimesIcon", 15)(18, Image_div_3_18_Template, 1, 0, null, 10);
    i0.ɵɵelementEnd()();
    i0.ɵɵtemplate(19, Image_div_3_div_19_Template, 2, 10, "div", 15);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵattribute("aria-modal", ctx_r1.maskVisible);
    i0.ɵɵadvance(3);
    i0.ɵɵattribute("aria-label", ctx_r1.rightAriaLabel());
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.rotateRightIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.rotateRightIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵattribute("aria-label", ctx_r1.leftAriaLabel());
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.rotateLeftIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.rotateLeftIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("disabled", ctx_r1.isZoomOutDisabled);
    i0.ɵɵattribute("aria-label", ctx_r1.zoomOutAriaLabel());
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.zoomOutIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.zoomOutIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("disabled", ctx_r1.isZoomInDisabled);
    i0.ɵɵattribute("aria-label", ctx_r1.zoomInAriaLabel());
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.zoomInIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.zoomInIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵattribute("aria-label", ctx_r1.closeAriaLabel());
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", !ctx_r1.closeIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.closeIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.previewVisible);
  }
}
class Image {
  document;
  config;
  cd;
  el;
  /**
   * Style class of the image element.
   * @group Props
   */
  imageClass;
  /**
   * Inline style of the image element.
   * @group Props
   */
  imageStyle;
  /**
   * Class of the element.
   * @group Props
   */
  styleClass;
  /**
   * Inline style of the element.
   * @group Props
   */
  style;
  /**
   * The source path for the main image.
   * @group Props
   */
  src;
  /**
   * The srcset definition for the main image.
   * @group Props
   */
  srcSet;
  /**
   * The sizes definition for the main image.
   * @group Props
   */
  sizes;
  /**
   * The source path for the preview image.
   * @group Props
   */
  previewImageSrc;
  /**
   * The srcset definition for the preview image.
   * @group Props
   */
  previewImageSrcSet;
  /**
   * The sizes definition for the preview image.
   * @group Props
   */
  previewImageSizes;
  /**
   * Attribute of the preview image element.
   * @group Props
   */
  alt;
  /**
   * Attribute of the image element.
   * @group Props
   */
  width;
  /**
   * Attribute of the image element.
   * @group Props
   */
  height;
  /**
   * Attribute of the image element.
   * @group Props
   */
  loading;
  /**
   * Target element to attach the dialog, valid values are "body" or a local ng-template variable of another element (note: use binding with brackets for template variables, e.g. [appendTo]="mydiv" for a div element having #mydiv as variable name).
   * @group Props
   */
  appendTo;
  /**
   * Controls the preview functionality.
   * @group Props
   */
  preview = false;
  /**
   * Transition options of the show animation
   * @group Props
   */
  showTransitionOptions = '150ms cubic-bezier(0, 0, 0.2, 1)';
  /**
   * Transition options of the hide animation
   * @group Props
   */
  hideTransitionOptions = '150ms cubic-bezier(0, 0, 0.2, 1)';
  /**
   * Triggered when the preview overlay is shown.
   * @group Emits
   */
  onShow = new EventEmitter();
  /**
   * Triggered when the preview overlay is hidden.
   * @group Emits
   */
  onHide = new EventEmitter();
  /**
   * This event is triggered if an error occurs while loading an image file.
   * @param {Event} event - Browser event.
   * @group Emits
   */
  onImageError = new EventEmitter();
  mask;
  previewButton;
  closeButton;
  templates;
  indicatorTemplate;
  rotateRightIconTemplate;
  rotateLeftIconTemplate;
  zoomOutIconTemplate;
  zoomInIconTemplate;
  closeIconTemplate;
  maskVisible = false;
  previewVisible = false;
  rotate = 0;
  scale = 1;
  previewClick = false;
  container;
  wrapper;
  get isZoomOutDisabled() {
    return this.scale - this.zoomSettings.step <= this.zoomSettings.min;
  }
  get isZoomInDisabled() {
    return this.scale + this.zoomSettings.step >= this.zoomSettings.max;
  }
  zoomSettings = {
    default: 1,
    step: 0.1,
    max: 1.5,
    min: 0.5
  };
  constructor(document, config, cd, el) {
    this.document = document;
    this.config = config;
    this.cd = cd;
    this.el = el;
  }
  ngAfterContentInit() {
    this.templates?.forEach(item => {
      switch (item.getType()) {
        case 'indicator':
          this.indicatorTemplate = item.template;
          break;
        case 'rotaterighticon':
          this.rotateRightIconTemplate = item.template;
          break;
        case 'rotatelefticon':
          this.rotateLeftIconTemplate = item.template;
          break;
        case 'zoomouticon':
          this.zoomOutIconTemplate = item.template;
          break;
        case 'zoominicon':
          this.zoomInIconTemplate = item.template;
          break;
        case 'closeicon':
          this.closeIconTemplate = item.template;
          break;
        default:
          this.indicatorTemplate = item.template;
          break;
      }
    });
  }
  onImageClick() {
    if (this.preview) {
      this.maskVisible = true;
      this.previewVisible = true;
      DomHandler.blockBodyScroll();
    }
  }
  onMaskClick() {
    if (!this.previewClick) {
      this.closePreview();
    }
    this.previewClick = false;
  }
  onMaskKeydown(event) {
    switch (event.code) {
      case 'Escape':
        this.onMaskClick();
        setTimeout(() => {
          DomHandler.focus(this.previewButton.nativeElement);
        }, 25);
        event.preventDefault();
        break;
      default:
        break;
    }
  }
  onPreviewImageClick() {
    this.previewClick = true;
  }
  rotateRight() {
    this.rotate += 90;
    this.previewClick = true;
  }
  rotateLeft() {
    this.rotate -= 90;
    this.previewClick = true;
  }
  zoomIn() {
    this.scale = this.scale + this.zoomSettings.step;
    this.previewClick = true;
  }
  zoomOut() {
    this.scale = this.scale - this.zoomSettings.step;
    this.previewClick = true;
  }
  onAnimationStart(event) {
    switch (event.toState) {
      case 'visible':
        this.container = event.element;
        this.wrapper = this.container?.parentElement;
        this.appendContainer();
        this.moveOnTop();
        setTimeout(() => {
          DomHandler.focus(this.closeButton.nativeElement);
        }, 25);
        break;
      case 'void':
        DomHandler.addClass(this.wrapper, 'p-component-overlay-leave');
        break;
    }
  }
  onAnimationEnd(event) {
    switch (event.toState) {
      case 'void':
        ZIndexUtils.clear(this.wrapper);
        this.maskVisible = false;
        this.container = null;
        this.wrapper = null;
        this.cd.markForCheck();
        this.onHide.emit({});
        break;
      case 'visible':
        this.onShow.emit({});
        break;
    }
  }
  moveOnTop() {
    ZIndexUtils.set('modal', this.wrapper, this.config.zIndex.modal);
  }
  appendContainer() {
    if (this.appendTo) {
      if (this.appendTo === 'body') this.document.body.appendChild(this.wrapper);else DomHandler.appendChild(this.wrapper, this.appendTo);
    }
  }
  imagePreviewStyle() {
    return {
      transform: 'rotate(' + this.rotate + 'deg) scale(' + this.scale + ')'
    };
  }
  get zoomImageAriaLabel() {
    return this.config.translation.aria ? this.config.translation.aria.zoomImage : undefined;
  }
  containerClass() {
    return {
      'p-image p-component': true,
      'p-image-preview-container': this.preview
    };
  }
  handleToolbarClick(event) {
    event.stopPropagation();
  }
  closePreview() {
    this.previewVisible = false;
    this.rotate = 0;
    this.scale = this.zoomSettings.default;
    DomHandler.unblockBodyScroll();
  }
  imageError(event) {
    this.onImageError.emit(event);
  }
  rightAriaLabel() {
    return this.config.translation.aria ? this.config.translation.aria.rotateRight : undefined;
  }
  leftAriaLabel() {
    return this.config.translation.aria ? this.config.translation.aria.rotateLeft : undefined;
  }
  zoomInAriaLabel() {
    return this.config.translation.aria ? this.config.translation.aria.zoomIn : undefined;
  }
  zoomOutAriaLabel() {
    return this.config.translation.aria ? this.config.translation.aria.zoomOut : undefined;
  }
  closeAriaLabel() {
    return this.config.translation.aria ? this.config.translation.aria.close : undefined;
  }
  onKeydownHandler(event) {
    if (this.previewVisible) {
      this.closePreview();
    }
  }
  static ɵfac = function Image_Factory(t) {
    return new (t || Image)(i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(i1.PrimeNGConfig), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: Image,
    selectors: [["p-image"]],
    contentQueries: function Image_ContentQueries(rf, ctx, dirIndex) {
      if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, PrimeTemplate, 4);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.templates = _t);
      }
    },
    viewQuery: function Image_Query(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵviewQuery(_c0, 5);
        i0.ɵɵviewQuery(_c1, 5);
        i0.ɵɵviewQuery(_c2, 5);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.mask = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.previewButton = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.closeButton = _t.first);
      }
    },
    hostAttrs: [1, "p-element"],
    hostBindings: function Image_HostBindings(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵlistener("keydown.escape", function Image_keydown_escape_HostBindingHandler($event) {
          return ctx.onKeydownHandler($event);
        }, false, i0.ɵɵresolveDocument);
      }
    },
    inputs: {
      imageClass: "imageClass",
      imageStyle: "imageStyle",
      styleClass: "styleClass",
      style: "style",
      src: "src",
      srcSet: "srcSet",
      sizes: "sizes",
      previewImageSrc: "previewImageSrc",
      previewImageSrcSet: "previewImageSrcSet",
      previewImageSizes: "previewImageSizes",
      alt: "alt",
      width: "width",
      height: "height",
      loading: "loading",
      appendTo: "appendTo",
      preview: "preview",
      showTransitionOptions: "showTransitionOptions",
      hideTransitionOptions: "hideTransitionOptions"
    },
    outputs: {
      onShow: "onShow",
      onHide: "onHide",
      onImageError: "onImageError"
    },
    decls: 4,
    vars: 16,
    consts: [["previewButton", ""], ["defaultTemplate", ""], ["mask", ""], ["closeButton", ""], [3, "ngClass", "ngStyle"], [3, "error", "ngStyle"], ["type", "button", "class", "p-image-preview-indicator", "style", "border: 'none';", 3, "ngStyle", "click", 4, "ngIf"], ["class", "p-image-mask p-component-overlay p-component-overlay-enter", "role", "dialog", "pFocusTrap", "", 3, "click", "keydown", 4, "ngIf"], ["type", "button", 1, "p-image-preview-indicator", 2, "border", "'none'", 3, "click", "ngStyle"], [4, "ngIf", "ngIfElse"], [4, "ngTemplateOutlet"], [3, "styleClass"], ["role", "dialog", "pFocusTrap", "", 1, "p-image-mask", "p-component-overlay", "p-component-overlay-enter", 3, "click", "keydown"], [1, "p-image-toolbar", 3, "click"], ["type", "button", 1, "p-image-action", "p-link", 3, "click"], [4, "ngIf"], ["type", "button", 1, "p-image-action", "p-link", 3, "click", "disabled"], [1, "p-image-preview", 3, "click", "ngStyle"]],
    template: function Image_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelementStart(0, "span", 4)(1, "img", 5);
        i0.ɵɵlistener("error", function Image_Template_img_error_1_listener($event) {
          return ctx.imageError($event);
        });
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(2, Image_button_2_Template, 5, 7, "button", 6)(3, Image_div_3_Template, 20, 19, "div", 7);
        i0.ɵɵelementEnd();
      }
      if (rf & 2) {
        i0.ɵɵclassMap(ctx.styleClass);
        i0.ɵɵproperty("ngClass", ctx.containerClass())("ngStyle", ctx.style);
        i0.ɵɵadvance();
        i0.ɵɵclassMap(ctx.imageClass);
        i0.ɵɵproperty("ngStyle", ctx.imageStyle);
        i0.ɵɵattribute("src", ctx.src, i0.ɵɵsanitizeUrl)("srcset", ctx.srcSet)("sizes", ctx.sizes)("alt", ctx.alt)("width", ctx.width)("height", ctx.height)("loading", ctx.loading);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.preview);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.maskVisible);
      }
    },
    dependencies: () => [i2.NgClass, i2.NgIf, i2.NgTemplateOutlet, i2.NgStyle, RefreshIcon, EyeIcon, UndoIcon, SearchMinusIcon, SearchPlusIcon, TimesIcon, i3.FocusTrap],
    styles: ["@layer primeng{.p-image-mask{display:flex;align-items:center;justify-content:center}.p-image-preview-container{position:relative;display:inline-block;line-height:0}.p-image-preview-indicator{position:absolute;left:0;top:0;width:100%;height:100%;outline:none;border:none;padding:0;display:flex;align-items:center;justify-content:center;opacity:0;transition:opacity .3s}.p-image-preview-icon.pi{font-size:1.5rem}.p-image-preview-icon.p-icon{scale:1.5}.p-image-preview-container:hover>.p-image-preview-indicator{opacity:1;cursor:pointer}.p-image-preview-container>img{cursor:pointer}.p-image-toolbar{position:absolute;top:0;right:0;display:flex;z-index:1}.p-image-action.p-link{display:flex;justify-content:center;align-items:center}.p-image-action.p-link[disabled]{opacity:.5}.p-image-preview{transition:transform .15s;max-width:100vw;max-height:100vh}}\n"],
    encapsulation: 2,
    data: {
      animation: [trigger('animation', [transition('void => visible', [style({
        transform: 'scale(0.7)',
        opacity: 0
      }), animate('{{showTransitionParams}}')]), transition('visible => void', [animate('{{hideTransitionParams}}', style({
        transform: 'scale(0.7)',
        opacity: 0
      }))])])]
    },
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Image, [{
    type: Component,
    args: [{
      selector: 'p-image',
      template: `
        <span [ngClass]="containerClass()" [class]="styleClass" [ngStyle]="style">
            <img [attr.src]="src" [attr.srcset]="srcSet" [attr.sizes]="sizes" [attr.alt]="alt" [attr.width]="width" [attr.height]="height" [attr.loading]="loading" [ngStyle]="imageStyle" [class]="imageClass" (error)="imageError($event)" />
            <button *ngIf="preview" [attr.aria-label]="zoomImageAriaLabel" type="button" class="p-image-preview-indicator" (click)="onImageClick()" #previewButton [ngStyle]="{ height: height + 'px', width: width + 'px' }" style="border: 'none';">
                <ng-container *ngIf="indicatorTemplate; else defaultTemplate">
                    <ng-container *ngTemplateOutlet="indicatorTemplate"></ng-container>
                </ng-container>
                <ng-template #defaultTemplate>
                    <EyeIcon [styleClass]="'p-image-preview-icon'" />
                </ng-template>
            </button>
            <div #mask class="p-image-mask p-component-overlay p-component-overlay-enter" *ngIf="maskVisible" [attr.aria-modal]="maskVisible" role="dialog" (click)="onMaskClick()" (keydown)="onMaskKeydown($event)" pFocusTrap>
                <div class="p-image-toolbar" (click)="handleToolbarClick($event)">
                    <button class="p-image-action p-link" (click)="rotateRight()" type="button" [attr.aria-label]="rightAriaLabel()">
                        <RefreshIcon *ngIf="!rotateRightIconTemplate" />
                        <ng-template *ngTemplateOutlet="rotateRightIconTemplate"></ng-template>
                    </button>
                    <button class="p-image-action p-link" (click)="rotateLeft()" type="button" [attr.aria-label]="leftAriaLabel()">
                        <UndoIcon *ngIf="!rotateLeftIconTemplate" />
                        <ng-template *ngTemplateOutlet="rotateLeftIconTemplate"></ng-template>
                    </button>
                    <button class="p-image-action p-link" (click)="zoomOut()" type="button" [disabled]="isZoomOutDisabled" [attr.aria-label]="zoomOutAriaLabel()">
                        <SearchMinusIcon *ngIf="!zoomOutIconTemplate" />
                        <ng-template *ngTemplateOutlet="zoomOutIconTemplate"></ng-template>
                    </button>
                    <button class="p-image-action p-link" (click)="zoomIn()" type="button" [disabled]="isZoomInDisabled" [attr.aria-label]="zoomInAriaLabel()">
                        <SearchPlusIcon *ngIf="!zoomInIconTemplate" />
                        <ng-template *ngTemplateOutlet="zoomInIconTemplate"></ng-template>
                    </button>
                    <button class="p-image-action p-link" type="button" (click)="closePreview()" [attr.aria-label]="closeAriaLabel()" #closeButton>
                        <TimesIcon *ngIf="!closeIconTemplate" />
                        <ng-template *ngTemplateOutlet="closeIconTemplate"></ng-template>
                    </button>
                </div>
                <div
                    *ngIf="previewVisible"
                    [@animation]="{ value: 'visible', params: { showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions } }"
                    (@animation.start)="onAnimationStart($event)"
                    (@animation.done)="onAnimationEnd($event)"
                >
                    <img [attr.src]="previewImageSrc ? previewImageSrc : src" [attr.srcset]="previewImageSrcSet" [attr.sizes]="previewImageSizes" class="p-image-preview" [ngStyle]="imagePreviewStyle()" (click)="onPreviewImageClick()" />
                </div>
            </div>
        </span>
    `,
      animations: [trigger('animation', [transition('void => visible', [style({
        transform: 'scale(0.7)',
        opacity: 0
      }), animate('{{showTransitionParams}}')]), transition('visible => void', [animate('{{hideTransitionParams}}', style({
        transform: 'scale(0.7)',
        opacity: 0
      }))])])],
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      host: {
        class: 'p-element'
      },
      styles: ["@layer primeng{.p-image-mask{display:flex;align-items:center;justify-content:center}.p-image-preview-container{position:relative;display:inline-block;line-height:0}.p-image-preview-indicator{position:absolute;left:0;top:0;width:100%;height:100%;outline:none;border:none;padding:0;display:flex;align-items:center;justify-content:center;opacity:0;transition:opacity .3s}.p-image-preview-icon.pi{font-size:1.5rem}.p-image-preview-icon.p-icon{scale:1.5}.p-image-preview-container:hover>.p-image-preview-indicator{opacity:1;cursor:pointer}.p-image-preview-container>img{cursor:pointer}.p-image-toolbar{position:absolute;top:0;right:0;display:flex;z-index:1}.p-image-action.p-link{display:flex;justify-content:center;align-items:center}.p-image-action.p-link[disabled]{opacity:.5}.p-image-preview{transition:transform .15s;max-width:100vw;max-height:100vh}}\n"]
    }]
  }], () => [{
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }, {
    type: i1.PrimeNGConfig
  }, {
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }], {
    imageClass: [{
      type: Input
    }],
    imageStyle: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    style: [{
      type: Input
    }],
    src: [{
      type: Input
    }],
    srcSet: [{
      type: Input
    }],
    sizes: [{
      type: Input
    }],
    previewImageSrc: [{
      type: Input
    }],
    previewImageSrcSet: [{
      type: Input
    }],
    previewImageSizes: [{
      type: Input
    }],
    alt: [{
      type: Input
    }],
    width: [{
      type: Input
    }],
    height: [{
      type: Input
    }],
    loading: [{
      type: Input
    }],
    appendTo: [{
      type: Input
    }],
    preview: [{
      type: Input
    }],
    showTransitionOptions: [{
      type: Input
    }],
    hideTransitionOptions: [{
      type: Input
    }],
    onShow: [{
      type: Output
    }],
    onHide: [{
      type: Output
    }],
    onImageError: [{
      type: Output
    }],
    mask: [{
      type: ViewChild,
      args: ['mask']
    }],
    previewButton: [{
      type: ViewChild,
      args: ['previewButton']
    }],
    closeButton: [{
      type: ViewChild,
      args: ['closeButton']
    }],
    templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }],
    onKeydownHandler: [{
      type: HostListener,
      args: ['document:keydown.escape', ['$event']]
    }]
  });
})();
class ImageModule {
  static ɵfac = function ImageModule_Factory(t) {
    return new (t || ImageModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: ImageModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule, SharedModule, RefreshIcon, EyeIcon, UndoIcon, SearchMinusIcon, SearchPlusIcon, TimesIcon, FocusTrapModule, SharedModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ImageModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, SharedModule, RefreshIcon, EyeIcon, UndoIcon, SearchMinusIcon, SearchPlusIcon, TimesIcon, FocusTrapModule],
      exports: [Image, SharedModule],
      declarations: [Image]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { Image, ImageModule };
