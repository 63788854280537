<div class="form-input flex flex-column gap-2">
  <h3 class="mb-2">{{ label }}</h3>
  <p-pickList
    [id]="inputId"
    *ngIf="options"
    [source]="options"
    [target]="currentSelection"
    sourceHeader="Dostępne"
    targetHeader="Wybrane"
    [dragdrop]="true"
    [responsive]="true"
    [sourceStyle]="{ height: '15rem' }"
    [targetStyle]="{ height: '15rem' }"
    breakpoint="1200px"
    (onMoveToSource)="onMove($event)"
    (onMoveToTarget)="onMove($event)"
    (onTargetReorder)="onReorder($event)"
    (onMoveAllToSource)="onMove($event)"
    (onMoveAllToTarget)="onMove($event)"
  >
    >
    <ng-template let-element pTemplate="item">
      <p class="element-title">{{ element.name }}</p>
      <p class="element-subtitle">{{ element.name_short }}</p>
    </ng-template>
  </p-pickList>
</div>
