import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropdownChangeEvent } from 'primeng/dropdown';

@Component({
  selector: 'app-dropdown-string-value-filter',
  templateUrl: './dropdown-string-value-filter.component.html',
  styleUrl: './dropdown-string-value-filter.component.scss',
})
export class DropdownStringValueFilterComponent implements OnInit {
  ngOnInit(): void {
    this.transformData();
  }
  @Input() selectedOption: string | null = null;
  @Input({ required: true }) options!: any[];
  @Input({ required: true }) placeholder!: string;
  @Input() set clear(value: boolean) {
    if (value === true) {
      this.onClear();
    }
  }

  transformData() {
    const key = this.findDynamicKey(this.options);
    this.options = this.options.map((item) => ({
      label: item[key] || 'Brak wartości',
      value: item[key] || 'Brak wartości',
    }));
  }
  findDynamicKey(data: any[]): string {
    for (const item of data) {
      const keys = Object.keys(item);
      for (const key of keys) {
        if (item[key] !== null && item[key] !== undefined) {
          return key;
        }
      }
    }
    return '';
  }

  @Output() changeSorting = new EventEmitter<string>();
  onChange(event: DropdownChangeEvent) {
    this.changeSorting.emit(event.value);
  }

  onClear() {
    this.selectedOption = null;
  }
}
