import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-decimal-table-field',
  templateUrl: './decimal-table-field.component.html',
  styleUrl: './decimal-table-field.component.scss',
})
export class DecimalTableFieldComponent {
  @Input({ required: true }) value!: number;
}
