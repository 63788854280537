export enum LOCATION_API_ENDPOINTS {
  base = 'locations/',
  Export = base + 'export',
  LocationsList = base + 'list',
  LocationsAdd = base + 'store',
  LocationsDelete = base + 'delete',
  LocationsEdit = base + 'edit/',
  LocationsDetails = base + 'get-data/',
  LocationsGetDataToEdit = base + 'get-data/',
}
